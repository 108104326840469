import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        success: 'border-transparent bg-green-500 text-white hover:bg-green-600',
        outline: 'text-foreground',
      },
      size: {
        default: 'px-2.5 py-0.5 text-xs',
        small: 'px-1 py-0 text-[10px] h-5',
      },
      inverse: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'destructive',
        inverse: true,
        class: 'border-destructive bg-transparent text-destructive hover:bg-destructive/10',
      },
      {
        variant: 'default',
        inverse: true,
        class: 'border-primary bg-transparent text-primary hover:bg-primary/10',
      },
      {
        variant: 'secondary',
        inverse: true,
        class: 'border-secondary bg-transparent text-secondary hover:bg-secondary/10',
      },
      {
        variant: 'success',
        inverse: true,
        class: 'border-green-500 bg-transparent text-green-500 hover:bg-green-500/10',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
      inverse: false,
    },
  }
);
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  inverse?: boolean;
}

function Badge({ className, variant, size, inverse, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, size, inverse }), className)} {...props} />;
}

export { Badge, badgeVariants };
