import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Extracts the initials from the first two words.
 *
 * @param {string} name - The full name to extract initials from.
 * @returns {string} The initials of the name.
 */
export function getInitials(name: string | undefined) {
  if (!name) return name;
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 2)
    .toUpperCase();
}
