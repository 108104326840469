import React from 'react';

enum Enum_Componentglobalspace_Height {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

type GlobalSpace = {
  height: Enum_Componentglobalspace_Height;
};

const heightMap = {
  [Enum_Componentglobalspace_Height.Xs]: 'h-2',
  [Enum_Componentglobalspace_Height.Sm]: 'h-4',
  [Enum_Componentglobalspace_Height.Md]: 'h-8',
  [Enum_Componentglobalspace_Height.Lg]: 'h-16',
  [Enum_Componentglobalspace_Height.Xl]: 'h-32',
};

const SpaceGap: React.FC<GlobalSpace> = ({ height }) => {
  return <div className={heightMap[height]} />;
};

export default SpaceGap;
