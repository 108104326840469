import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { ComponentHeroHero, Maybe, UploadFileEntityResponse } from 'types/cms';

interface HeroPage extends Omit<ComponentHeroHero, 'id' | 'image'> {
  image?: Maybe<UploadFileEntityResponse> | undefined;
  children?: JSX.Element;
}

const Hero: React.FC<HeroPage> = ({
  title,
  image,
  description,
  children,
  center,
  ButtonText,
  ButtonUrl,
}) => {
  return (
    <div
      className={cn(
        'relative overflow-hidden min-h-52 flex',
        center
          ? 'items-center justify-center sm:min-h-[500px] sm:max-w-7xl sm:mx-auto'
          : 'items-start justify-start sm:min-h-[500px] sm:max-w-7xl sm:flex-col sm:justify-center sm:mx-auto'
      )}
    >
      <Image
        priority
        fill
        sizes="(min-width: 1280px) 1200px, calc(93.75vw + 19px)"
        src={image?.data?.attributes?.url || ''}
        alt={image?.data?.attributes?.alternativeText || 'Hero image'}
        className="object-cover"
      />
      {title && (
        <div className="absolute inset-0 bg-gradient-to-r from-black/70 via-black/40 to-black/0 z-0" />
      )}
      <div
        className={cn(
          'relative flex gap-4',
          center
            ? 'items-center justify-center p-4 sm:flex-row'
            : 'items-center justify-start p-4 w-[90%] max-w-6xl mx-auto sm:flex-row'
        )}
      >
        <div className={cn(center ? 'text-center' : 'flex-[0_0_70%] sm:text-left')}>
          {title && (
            <h1
              className={cn(
                'text-white font-extrabold uppercase text-4xl leading-snug mt-2 sm:text-5xl',
                center && 'mx-auto'
              )}
            >
              <span className="text-yellow-500">{title}</span>
            </h1>
          )}
          {description && <h2 className="text-white text-lg mt-4">{description}</h2>}
          {ButtonUrl && (
            <Button asChild size="xl" variant="default" className="mt-4">
              <Link href={{ pathname: ButtonUrl }}>{ButtonText}</Link>
            </Button>
          )}
        </div>

        {children && <div className="hidden sm:block">{children}</div>}
      </div>
    </div>
  );
};

export default Hero;
