import React from 'react';
import { Label } from '@/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Checkbox } from '@/components/ui/checkbox';
import { CheckoutModalType } from './CheckoutModalType';
import { UseFormReturnType } from '@mantine/form';
import { BsInfoCircle } from 'react-icons/bs';

const CheckoutPreferences = ({ form }: { form: UseFormReturnType<CheckoutModalType> }) => {
  return (
    <TooltipProvider>
      <div className="space-y-6">
        <div>
          <div className="flex items-center gap-2">
            <h4>Gift Aid Preferences</h4>
            <Tooltip>
              <TooltipTrigger
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <button type="button">
                  <BsInfoCircle className="text-gray-600 cursor-pointer" />
                </button>
              </TooltipTrigger>

              <TooltipContent className="max-w-sm">
                Gift Aid is a tax relief scheme, allowing UK Charities to reclaim an extra 25% in
                tax on every eligible donation made by a UK taxpayer. If you are a UK taxpayer, why
                not consider making your donation go further, at no extra cost, by selecting Gift
                Aid. For every £1 you donate, we can claim a further 25 pence, at no extra cost to
                you.
              </TooltipContent>
            </Tooltip>
          </div>

          <p className="text-sm">
            If you are a UK taxpayer, the value of your gift can increase by 25% at no extra cost to
            you!
          </p>

          <div className="flex items-center gap-2">
            <Checkbox
              checked={form.values.giftaid}
              onCheckedChange={(checked) => form.setFieldValue('giftaid', Boolean(checked))}
              id="giftaid"
            />
            <Label htmlFor="giftaid" className="text-sm">
              I am a UK taxpayer, donating as an individual, and would like Human Aid & Advocacy to
              claim Gift Aid on my donation
            </Label>
          </div>
        </div>

        <div>
          <h4>Receive Feedback?</h4>

          <div className="flex items-center gap-2">
            <Checkbox
              checked={form.values.keepInTouch}
              onCheckedChange={(checked) => form.setFieldValue('keepInTouch', Boolean(checked))}
              id="feedback-email"
            />
            <Label htmlFor="feedback-email" className="text-sm">
              Yes, I would like to receive evidence of the donation's life-changing impact
            </Label>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default CheckoutPreferences;
