import React from 'react';

type LabeledSeparatorProps = {
  label: string;
};

const LabeledSeparator: React.FC<LabeledSeparatorProps> = ({ label }) => {
  return (
    <div className="relative flex items-center my-4">
      <div className="flex-grow border-t border-gray-300" />
      <span className="mx-4 text-sm text-gray-500">{label}</span>
      <div className="flex-grow border-t border-gray-300" />
    </div>
  );
};

export default LabeledSeparator;
