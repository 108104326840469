import gql from 'graphql-tag';

export const GET_FUNDRAISE_PAGE_FROM_DONATION = `
query Donationsdfefdef($filters: DonationFiltersInput) {
  donations(filters: $filters) {
    data {
      attributes {
        fundraise_page {
          data {
            attributes {
              name
              slug
              fullName
              user {
                data {
                  attributes {
                    email
                  }
                }
              }
            }
          }
        }
        amount
        donorName
        includeName
        intention
        interval
        message
      }
    }
  }
}`;

export const GET_EVENT_ATTENDEES_PAID = `
query Donations($filters: DonationFiltersInput, $pagination: PaginationArg) {
  donations(filters: $filters, pagination: $pagination) {
    data {
      attributes {
        event_attendees {
          data {
            id
            attributes {
              email
              event {
                data {
                  attributes {
                    name
                    emailSubjectLine
                    additionalEmailText
                    emailConfirmationTemplateId
                    emailListId
                    datetime
                    location
                    eventContactEmail
                    eventContactName
                  }
                }
              }
              name
            }
          }
        }
      }
      id
    }
  }
}`;
export const GET_DASHBOARD = gql`
  query Query(
    $myDonationFilters: DonationFiltersInput
    $pagination: PaginationArg
    $sort: [String]
    $myfundriasePages: FundraisePageFiltersInput
    $pageDonations: DonationFiltersInput
    $team: FundraiseTeamFiltersInput
    $publicationState: PublicationState
    $filters: FundraiseTeamCampaignFiltersInput
    $memberPagesFilters2: FundraisePageFiltersInput
  ) {
    donations(filters: $myDonationFilters, pagination: $pagination) {
      data {
        attributes {
          amount
          appeal {
            data {
              attributes {
                title
                region
                category
              }
            }
          }
          donationDate
          fundraise_page {
            data {
              attributes {
                name
              }
            }
          }
          intention
          interval
        }
        id
      }
    }
    fundraisePages(
      pagination: $pagination
      filters: $myfundriasePages
      publicationState: $publicationState
      sort: $sort
    ) {
      data {
        attributes {
          slug
          name
          fullName
          endDate
          donations(filters: $pageDonations) {
            data {
              attributes {
                amount
              }
            }
          }
          goal
        }
        id
      }
    }
    fundraiseTeams(filters: $team) {
      data {
        id
        attributes {
          name
          slug
          fundraise_team_campaigns(filters: $filters) {
            data {
              id
              attributes {
                name
                campaign_page {
                  data {
                    attributes {
                      name
                      goal
                      endDate
                      slug
                      donations(filters: $pageDonations) {
                        data {
                          id
                          attributes {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
                member_pages(
                  sort: $sort
                  publicationState: $publicationState
                  filters: $memberPagesFilters2
                ) {
                  data {
                    id
                    attributes {
                      fullName
                      name
                      goal
                      slug
                      endDate
                      donations(filters: $pageDonations) {
                        data {
                          id
                          attributes {
                            amount
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_GLOBAL_LEADERBOARD_CONFIG = gql`
  query {
    global {
      data {
        attributes {
          leaderboardStartDate
        }
      }
    }
  }
`;
export const GET_GLOBAL_CONFIG = gql`
  query ($header: String!, $footer: String!) {
    global {
      data {
        attributes {
          meta {
            description
            metaTitle
            twitterCardType
            shareImage {
              data {
                attributes {
                  formats
                  mime
                }
              }
            }
          }
          metaTitleSuffix
          twitterUsername
        }
      }
    }
    header: renderNavigation(type: TREE, menuOnly: false, navigationIdOrSlug: $header) {
      id
      title
      path
      items {
        id
        title
        path
      }
    }

    footer: renderNavigation(type: TREE, menuOnly: false, navigationIdOrSlug: $footer) {
      id
      title
      path
      items {
        id
        title
        path
      }
    }
  }
`;

export const GET_PAGE_PATHS = gql`
  query Page($filters: PageFiltersInput) {
    pages(filters: $filters, pagination: { limit: 100, start: 0 }) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

export const GET_FUNDRAISABLE_APPEALS = gql`
  query fundraiseAppeal($pagination: PaginationArg, $appeals: AppealFiltersInput) {
    appeals(pagination: $pagination, filters: $appeals, sort: "title") {
      data {
        id
        attributes {
          hideFromFundraising
          title
          sfId
          category
          region
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`;

export const GET_FUNDRAISE_PAGE_PATHS = gql`
  query FundraisePages(
    $filters: FundraisePageFiltersInput
    $publicationState: PublicationState
    $donateOptionsFilters2: ComponentDonationQuickDonateOptionsFiltersInput
  ) {
    fundraisePages(
      filters: $filters
      publicationState: $publicationState
      pagination: { limit: 100, start: 0 }
    ) {
      data {
        id
        attributes {
          slug
          appeals {
            data {
              attributes {
                title
                donateOptions(filters: $donateOptionsFilters2) {
                  id
                  sfId
                }
                sfUpsellProjectId
                sfId
              }
              id
            }
          }
        }
      }
    }
  }
`;
export const GET_FUNDRAISE_PAGE = gql`
  query FundraisePages(
    $filters: FundraisePageFiltersInput
    $publicationState: PublicationState
    $donateOptionsFilters2: ComponentDonationQuickDonateOptionsFiltersInput
    $donationsFilters2: DonationFiltersInput
  ) {
    fundraisePages(filters: $filters, publicationState: $publicationState) {
      data {
        id
        attributes {
          offlineDonations
          createdAt
          publishedAt
          endDate
          goal
          fullName
          name
          slug
          story
          coverPhoto {
            data {
              attributes {
                height
                width
                url
                name
                formats
                mime
                hash
                ext
              }
            }
          }
          appeals {
            data {
              attributes {
                image {
                  data {
                    attributes {
                      height
                      width
                      url
                      name
                      formats
                      mime
                      hash
                      ext
                    }
                  }
                }
                title
                showTitle
                region
                publishedAt
                body {
                  ... on ComponentGlobalImages {
                    bg
                    imageList {
                      id
                      text
                      image {
                        data {
                          attributes {
                            url
                            formats
                            mime
                            size
                            height
                            width
                          }
                        }
                      }
                    }
                  }
                  ... on ComponentGlobalSpace {
                    height
                  }
                  ... on ComponentGlobalNewsTickerList {
                    news_ticker_items {
                      data {
                        id
                        attributes {
                          content
                          prefix
                        }
                      }
                    }
                  }
                  ... on ComponentGlobalYouTube {
                    embedId
                    id
                  }
                  ... on ComponentGlobalEmbedContent {
                    id
                    link
                  }
                  ... on ComponentGlobalRichText {
                    anchorName
                    id
                    content
                  }
                  ... on ComponentGlobalSectionHeading {
                    id
                    title
                    cardGroup {
                      id
                      title
                      description
                      image {
                        data {
                          attributes {
                            alternativeText
                            ext
                            formats
                            mime
                            hash
                            height
                            name
                            size
                            url
                            width
                          }
                        }
                      }
                      btnText
                      btnUrl
                      showShareBtn
                      showBtn
                    }
                    footerLinkText
                    highlightBackground
                    footerLinkUrl
                    showFooter
                    useSlider
                    showBorder
                  }
                  ... on ComponentGlobalSectionWithText {
                    id
                    section {
                      id
                      heading
                      text
                      btnText
                      btnUrl
                      bgColour
                      videoLink
                      bottomBorder
                      dividerBorder
                      image {
                        data {
                          id
                          attributes {
                            alternativeText
                            ext
                            formats
                            mime
                            hash
                            name
                            url
                            width
                            height
                          }
                        }
                      }
                      showOnRight
                    }
                  }
                  ... on ComponentGlobalSubscribe {
                    id
                    title
                    description
                    btnText
                    image {
                      data {
                        attributes {
                          alternativeText
                          ext
                          formats
                          mime
                          hash
                          height
                          name
                          size
                          url
                          width
                        }
                      }
                    }
                  }
                  ... on ComponentGlobalTextSection {
                    id
                    heading
                    text
                    image {
                      data {
                        attributes {
                          alternativeText
                          ext
                          formats
                          mime
                          hash
                          height
                          name
                          url
                          width
                        }
                        id
                      }
                    }
                    showOnRight
                  }
                  ... on ComponentGlobalTitle {
                    id
                    title
                    hideOnMobile
                  }
                  ... on Error {
                    code
                    message
                  }
                }
                donateOptions(filters: $donateOptionsFilters2) {
                  id
                  sfId
                  frequency
                }
                sfUpsellProjectId
                zakat
                sfId
              }
              id
            }
          }
          donations(filters: $donationsFilters2, sort: "donationDate:desc") {
            data {
              id
              attributes {
                updatedAt
                interval
                donationDate
                amount
                donorName
                message
                includeDonationAmount
                includeName
                intention
                donationDate
                offline
              }
            }
          }
          user {
            data {
              id
            }
          }
          campaign {
            data {
              id
              attributes {
                name
                memberGoal
                inviteCode
                fundraise_team {
                  data {
                    id
                    attributes {
                      name
                      team_admins {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
                member_pages {
                  data {
                    id
                    attributes {
                      offlineDonations
                      goal
                      fullName
                      name
                      slug
                      donations(filters: $donationsFilters2) {
                        data {
                          attributes {
                            amount
                            interval
                          }
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          campaign_member {
            data {
              id
              attributes {
                name
                fundraise_team {
                  data {
                    attributes {
                      name
                      team_admins {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
                campaign_page {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_APPEALS = gql`
  query Page(
    $pagination: PaginationArg
    $filters: ComponentDonationQuickDonateOptionsFiltersInput
    $appeals: AppealFiltersInput
  ) {
    appeals(pagination: $pagination, filters: $appeals, sort: "title") {
      data {
        id
        attributes {
          metadata {
            id
            metaTitle
            description
            shareImage {
              data {
                attributes {
                  url
                  formats
                  mime
                }
              }
            }
          }
          category
          donateOptions(filters: $filters) {
            id
            displayText
            price
            type
            frequency
            sfId
            frequencyOccurrence
            active
            impact
            maxQuantity
            startDate
            endDate
          }
          hideFromFundraising
          hideFromProjectSelections
          image {
            data {
              attributes {
                url
                formats
                mime
              }
            }
          }
          region
          shortDescription
          slug
          title
          showTitle
          sadakah
          zakat
          sadakahJariya
          sfId
          sfUpsellProjectId
          body {
            ... on ComponentGlobalImages {
              bg
              imageList {
                id
                text
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                      size
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on ComponentGlobalSpace {
              height
            }
            ... on ComponentGlobalNewsTickerList {
              news_ticker_items {
                data {
                  id
                  attributes {
                    content
                    prefix
                  }
                }
              }
            }
            ... on ComponentGlobalYouTube {
              embedId
              id
            }
            ... on ComponentGlobalEmbedContent {
              id
              link
            }
            ... on ComponentGlobalRichText {
              anchorName
              id
              content
            }
            ... on ComponentGlobalSectionHeading {
              id
              title
              cardGroup {
                id
                title
                description
                image {
                  data {
                    attributes {
                      alternativeText
                      ext
                      formats
                      mime
                      hash
                      height
                      name
                      size
                      url
                      width
                    }
                  }
                }
                btnText
                btnUrl
                showShareBtn
                showBtn
              }
              footerLinkText
              highlightBackground
              footerLinkUrl
              showFooter
              useSlider
              showBorder
            }
            ... on ComponentGlobalSectionWithText {
              id
              section {
                id
                heading
                text
                btnText
                btnUrl
                bgColour
                videoLink
                bottomBorder
                dividerBorder
                image {
                  data {
                    id
                    attributes {
                      alternativeText
                      ext
                      formats
                      mime
                      hash
                      name
                      url
                      width
                      height
                    }
                  }
                }
                showOnRight
              }
            }
            ... on ComponentGlobalSubscribe {
              id
              title
              description
              btnText
              image {
                data {
                  attributes {
                    alternativeText
                    ext
                    formats
                    mime
                    hash
                    height
                    name
                    size
                    url
                    width
                  }
                }
              }
            }
            ... on ComponentGlobalTextSection {
              id
              heading
              text
              image {
                data {
                  attributes {
                    alternativeText
                    ext
                    formats
                    mime
                    hash
                    height
                    name
                    url
                    width
                  }
                  id
                }
              }
              showOnRight
            }
            ... on ComponentGlobalTitle {
              id
              title
              hideOnMobile
            }
            ... on Error {
              code
              message
            }
          }
        }
      }
    }
  }
`;

export const GET_APPEAL_PATHS = gql`
  query GETAPPEALPATHS($appeals: AppealFiltersInput) {
    appeals(filters: $appeals, pagination: { limit: 100, start: 0 }) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`;

export const GET_PAGE = gql`
  query Page(
    $filters: PageFiltersInput
    $lbDonationFilter: DonationFiltersInput
    $donateOptionsFilters2: ComponentDonationQuickDonateOptionsFiltersInput
  ) {
    pages(filters: $filters) {
      data {
        id
        attributes {
          body {
            ... on ComponentWrite2GovWriteToGov {
              write_to_gov {
                data {
                  id
                  attributes {
                    body
                    subject
                    showProgressBar
                    emails {
                      name
                    }
                  }
                }
              }
            }
            ... on ComponentDonationTeams {
              teams {
                data {
                  attributes {
                    createdAt
                    member_pages(filters: { publishedAt: { notNull: true } }) {
                      data {
                        id
                        attributes {
                          publishedAt
                          goal
                          name
                          fullName
                          slug
                          campaign_member {
                            data {
                              id
                            }
                          }
                          donations(filters: $lbDonationFilter) {
                            data {
                              id
                              attributes {
                                amount
                                interval
                                donationDate
                              }
                            }
                          }
                        }
                      }
                    }
                    name
                    campaign_page {
                      data {
                        id
                        attributes {
                          appeals {
                            data {
                              id
                              attributes {
                                title
                              }
                            }
                          }
                          publishedAt
                          goal
                          name
                          fullName
                          slug
                          donations(filters: $lbDonationFilter) {
                            data {
                              id
                              attributes {
                                amount
                                interval
                                donationDate
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  id
                }
              }
              id
            }
            ... on ComponentHeroHeroQuickDonate {
              id
              donateOptions(filters: $donateOptionsFilters2) {
                id
                displayText
                price
                type
                frequency
                sfId
                frequencyOccurrence
                active
                impact
                maxQuantity
                startDate
                endDate
              }
              hero {
                id
                title
                description
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                      size
                      height
                      width
                    }
                  }
                }
              }
              sadakah
              zakat
              donateDescription
            }
            ... on ComponentGlobalImages {
              bg
              imageList {
                id
                text
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                      size
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on ComponentGlobalSpace {
              height
            }
            ... on ComponentShopItem {
              id
              title
              description
              itemImage {
                data {
                  attributes {
                    url
                    formats
                    mime
                    size
                    height
                    width
                  }
                }
              }
              itemBtnText
              option {
                id
                colour
                size
                pocket
                url
                price
              }
            }
            ... on ComponentHeroHero {
              id
              title
              description
              ButtonText
              ButtonUrl
              center
              image {
                data {
                  attributes {
                    url
                    formats
                    mime
                  }
                }
              }
            }
            ... on ComponentGlobalSectionHeading {
              id
              title
              footerLinkText
              highlightBackground
              footerLinkUrl
              showFooter
              useSlider
              cardGroup {
                id
                title
                description
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                    }
                  }
                }
                btnText
                btnUrl
                showShareBtn
                showBtn
              }
              showBorder
            }
            ... on ComponentGlobalSubscribe {
              id
              title
              description
              btnText
              image {
                data {
                  attributes {
                    url
                    formats
                    mime
                  }
                }
              }
            }
            ... on Error {
              code
              message
            }
            ... on ComponentAppealsFeaturedAppeals {
              id
              title
              appeals {
                data {
                  attributes {
                    image {
                      data {
                        attributes {
                          url
                          formats
                          mime
                        }
                      }
                    }
                    shortDescription
                    title
                    slug
                  }
                }
              }
              footerLinkText
              footerLinkHref
            }
            ... on ComponentChallengesFeaturedEventsChallenges {
              id
              title
              challenges {
                data {
                  attributes {
                    shortDescription
                    slug
                    title
                    image {
                      data {
                        attributes {
                          url
                          formats
                          mime
                        }
                      }
                    }
                  }
                }
              }
              footerLinkText
              footerLinkHref
            }
            ... on ComponentGlobalTitle {
              hideOnMobile
              id
              title
            }
            ... on ComponentGlobalDonationQuery {
              filters
              query
            }
            ... on ComponentGlobalSectionWithText {
              id
              section {
                heading
                id
                showOnRight
                text
                btnText
                btnUrl
                bgColour
                videoLink
                bottomBorder
                dividerBorder
                image {
                  data {
                    id
                    attributes {
                      url
                      formats
                      mime
                    }
                  }
                }
              }
            }
            ... on ComponentGlobalYouTube {
              embedId
              id
            }
            ... on ComponentGlobalRichText {
              anchorName
              content
              id
            }
            ... on ComponentGlobalButtonWithText {
              id
              text
              btnText
              btnUrl
              btnColour
              btnOnRight
            }
            ... on ComponentGlobalEmbedContent {
              link
              id
            }
            ... on ComponentHeroHeroQuickDonateQuery {
              id
              DonationQuery {
                filters
                query
              }
              Hero {
                description
                id
                title
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                      size
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on ComponentGlobalNewsTickerList {
              news_ticker_items {
                data {
                  id
                  attributes {
                    content
                    prefix
                  }
                }
              }
            }
          }
          title
          sfId
          slug
          meta {
            id
            metaTitle
            description
            shareImage {
              data {
                attributes {
                  url
                  formats
                  mime
                }
              }
            }
          }
          createdAt
          publishedAt
          sadakah
          zakat
        }
      }
    }
  }
`;

export const GET_FUNDRAISING_TOTALS = gql`
  query FundraisingTotals(
    $donationFilters: DonationFiltersInput
    $campaignFilter: FundraisePageFiltersInput
    $pagination: PaginationArg
  ) {
    fundraisePages(filters: $campaignFilter, pagination: $pagination) {
      data {
        attributes {
          donations(filters: $donationFilters) {
            data {
              attributes {
                amount
                interval
                donationDate
              }
            }
          }
          offlineDonations
          goal
          appeals {
            data {
              attributes {
                title
              }
            }
          }
          name
          fullName
          slug
          campaign {
            data {
              attributes {
                member_pages {
                  data {
                    attributes {
                      donations(filters: $donationFilters) {
                        data {
                          id
                          attributes {
                            amount
                            interval
                            donationDate
                          }
                        }
                      }
                      offlineDonations
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
    }
  }
`;

export const GET_QURBANI_REPORT = `
query Donations($filters: DonationFiltersInput) {
  donations(filters: $filters) {
    data {
      id
      attributes {
        deliveryStreet
        deliveryTown
        deliveryPostCode
        qurbaniFat
        qurbaniSize
        qurbaniSpecialRequest
        stripe
      }
    }
  }
}
`;
export const GET_FUNDRAISING_REPORT = `
  query FundraisingTotals($donationFilters: DonationFiltersInput, $appealFilters: FundraisePageFiltersInput) {
    fundraisePages(filters: $appealFilters) {
      data {
        attributes {
          donations(filters: $donationFilters) {
            data {
              attributes {
                intention
                giftaid
                email
                amount
                interval
                donationDate
                offline
              }
            }
          }
          offlineDonations
          goal
          appeals {
            data {
              attributes {
                title
              }
            }
          }
          createdAt
          name
          fullName
          slug
          campaign_member {
            data {
              attributes {
                name
                fundraise_team {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          user {
            data {
              attributes {
                email
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
    }
  }
`;

export const GET_FUNDRAISING_PAGES = gql`
  query FundraisingParentPage($donationFilters: DonationFiltersInput) {
    fundraisePages(pagination: { limit: 100, start: 0 }) {
      data {
        attributes {
          donations(filters: $donationFilters) {
            data {
              attributes {
                amount
                interval
              }
            }
          }
          offlineDonations
          goal
          appeals {
            data {
              attributes {
                title
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                    }
                  }
                }
              }
            }
          }
          coverPhoto {
            data {
              attributes {
                url
                formats
                mime
              }
            }
          }
          name
          fullName
          slug
          story
          user {
            data {
              attributes {
                photo {
                  data {
                    attributes {
                      url
                      formats
                      mime
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EVENT_PAGES = gql`
  query EventsPages($sort: [String]) {
    events(publicationState: LIVE, pagination: { limit: 100, start: 0 }, sort: $sort) {
      data {
        id
        attributes {
          city
          ExternalLink
          venue
          hero {
            image {
              data {
                attributes {
                  alternativeText
                  ext
                  formats
                  mime
                  hash
                  height
                  name
                  url
                  width
                }
              }
            }
          }
          datetime
          name
          slug
          pricesFrom
        }
      }
    }
  }
`;
export const GET_EVENT_ATTENDEES = `
  query EventListAttendees($filters: EventAttendeeFiltersInput) {
    eventAttendees(pagination: { limit: 10000 }, filters: $filters) {
      meta {
        pagination {
          page
          pageCount
          pageSize
          total
        }
      }
      data {
        id
        attributes {
          customQuestionAnswer
          email
          name
          donation {
            data {
              attributes {
                email
                stripe
              }
            }
          }
          event {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_EVENT = gql`
  query Event(
    $filters: EventFiltersInput
    $donateOptionsFilters2: ComponentDonationQuickDonateOptionsFiltersInput
    $eventAttendeesFilters2: EventAttendeeFiltersInput
  ) {
    events(publicationState: LIVE, filters: $filters) {
      data {
        id
        attributes {
          datetime
          customQuestion
          emailSubjectLine
          emailListId
          emailConfirmationTemplateId
          eventContactName
          eventContactEmail
          additionalEmailText
          slug
          location
          name
          pricesFrom
          cost
          ticketsAvailable
          hero {
            image {
              data {
                attributes {
                  url
                  width
                  formats
                  mime
                  ext
                  alternativeText
                  hash
                  height
                }
              }
            }
          }
          meta {
            description
            id
            metaTitle
            twitterCardType
            shareImage {
              data {
                attributes {
                  formats
                  mime
                  url
                }
              }
            }
          }
          appeal {
            data {
              id
              attributes {
                sfId
                sfUpsellProjectId
                title
                slug
                shortDescription
                donateOptions(filters: $donateOptionsFilters2) {
                  sfId
                }
              }
            }
          }
          body {
            ... on ComponentGlobalCardGroup {
              id
              cardGroup {
                id
                title
                description
                image {
                  data {
                    attributes {
                      url
                      formats
                      mime
                      ext
                      alternativeText
                      hash
                      name
                      width
                      height
                    }
                    id
                  }
                }
                btnText
                btnUrl
                showShareBtn
                showBtn
              }
            }
            ... on ComponentGlobalYouTube {
              embedId
              id
            }
            ... on ComponentGlobalEmbedContent {
              id
              link
            }
            ... on ComponentGlobalRichText {
              anchorName
              id
              content
            }
            ... on ComponentGlobalSectionHeading {
              id
              title
              cardGroup {
                id
                title
                description
                image {
                  data {
                    attributes {
                      alternativeText
                      ext
                      formats
                      mime
                      hash
                      height
                      name
                      size
                      url
                      width
                    }
                  }
                }
                btnText
                btnUrl
                showShareBtn
                showBtn
              }
              footerLinkText
              highlightBackground
              footerLinkUrl
              showFooter
              useSlider
              showBorder
            }
            ... on ComponentGlobalSectionWithText {
              id
              section {
                id
                heading
                text
                btnText
                btnUrl
                bgColour
                videoLink
                bottomBorder
                dividerBorder
                image {
                  data {
                    id
                    attributes {
                      alternativeText
                      ext
                      formats
                      mime
                      hash
                      name
                      url
                      width
                      height
                    }
                  }
                }
                showOnRight
              }
            }
            ... on ComponentGlobalSubscribe {
              id
              title
              description
              btnText
              image {
                data {
                  attributes {
                    alternativeText
                    ext
                    formats
                    mime
                    hash
                    height
                    name
                    size
                    url
                    width
                  }
                }
              }
            }
            ... on ComponentGlobalTextSection {
              id
              heading
              text
              image {
                data {
                  attributes {
                    alternativeText
                    ext
                    formats
                    mime
                    hash
                    height
                    name
                    url
                    width
                  }
                  id
                }
              }
              showOnRight
            }
            ... on ComponentGlobalTitle {
              id
              title
              hideOnMobile
            }
            ... on Error {
              code
              message
            }
          }
          venue
          city
          ticketName
          event_attendees(filters: $eventAttendeesFilters2) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
