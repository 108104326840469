import React from 'react';
import Link from 'next/link';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import Image from 'next/image';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import fundraiseRegulator from '@/public/fundraising-regulator.webp';
import isbLogo from '@/public/Islamic_Scholar_Board_Logo_Black.png';
import { NavigationItem } from 'types/cms';

type FooterProps = {
  nav: NavigationItem[];
};

const Footer = ({ nav }: FooterProps) => {
  return (
    <div className="bg-yellow-400 py-8">
      <div className="container max-w-6xl px-4">
        <Accordion type="single" collapsible className="w-full">
          <div className="grid md:grid-cols-3 gap-8">
            {nav.map((firstLevel: NavigationItem) => (
              <AccordionItem
                key={firstLevel.id}
                value={`nav-${firstLevel.id}`}
                className="hover:bg-white"
              >
                <AccordionTrigger className="text-lg font-black p-2">
                  {firstLevel.title}
                </AccordionTrigger>
                <AccordionContent className="p-2">
                  {firstLevel.items?.map((item) => (
                    <Link
                      key={item?.id}
                      href={item?.path || '/'}
                      className="block text-black no-underline hover:underline"
                    >
                      {item?.title}
                    </Link>
                  ))}
                </AccordionContent>
              </AccordionItem>
            ))}

            <AccordionItem value="bank-details" className="hover:bg-white">
              <AccordionTrigger className="text-lg font-black p-2">BANK DETAILS</AccordionTrigger>
              <AccordionContent className="p-2">
                <p>Account Name: Human Aid & Advocacy</p>
                <p>Sort Code: 30-00-83</p>
                <p>Account Number: 01275801</p>
                <p>IBAN: GB20ARAY30008301275801</p>
              </AccordionContent>
            </AccordionItem>
          </div>
        </Accordion>

        <div className="mt-8 grid md:grid-cols-3 gap-8">
          <div className="text-center md:text-left md:p-2">
            <h4 className="text-lg font-black">FOLLOW US</h4>
            <div className="flex justify-center md:justify-start space-x-4 mt-2">
              <Link href="https://www.instagram.com/humanaidadvocacy" target="_blank">
                <BsInstagram size="1.5rem" className="text-black" />
              </Link>
              <Link href="https://www.facebook.com/humanaidadvocacy" target="_blank">
                <BsFacebook size="1.5rem" className="text-black" />
              </Link>
              <Link href="https://twitter.com/humanaidadvoc" target="_blank">
                <BsTwitter size="1.5rem" className="text-black" />
              </Link>
              <Link href="https://www.youtube.com/@humanaidadvocacy" target="_blank">
                <BsYoutube size="1.5rem" className="text-black" />
              </Link>
              <Link href="https://www.linkedin.com/company/humanaidadvocacy/" target="_blank">
                <BsLinkedin size="1.5rem" className="text-black" />
              </Link>
            </div>
          </div>

          <div className="md:p-2">
            <h4 className="text-lg font-bold">Head Office</h4>
            <div className="space-y-1">
              <p className="flex items-center">
                <FaMapMarkerAlt className="mr-2" /> 3 Coke Street, London, E1 1ER
              </p>
              <p className="flex items-center">
                <GrMail className="mr-2" /> info@humanaid.org
              </p>
              <p className="flex items-center">
                <FaPhoneAlt className="mr-2" /> +44 (0)20 3875 0563
              </p>
              <h4 className="text-lg font-bold pt-3">Manchester Office</h4>
              <p className="flex items-center">
                <FaMapMarkerAlt className="mr-2" /> 22a Clegg Street, Oldham, OL1 1PL
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <Image src={isbLogo} alt="ISB Logo" width={100} />
            <Image
              src={fundraiseRegulator}
              width={180}
              alt="Fundraising Regulator Logo"
              className="mt-4"
            />
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-black">
            © Copyright 2023 Human Aid & Advocacy. All rights reserved. Registered Charity No.
            1138111
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
