import { Montserrat } from 'next/font/google';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';
import { Provider } from 'react-redux';
import { GET_GLOBAL_CONFIG } from '@/cms/queries';
import { Global, NavigationItem } from 'types/cms';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import store from '@/redux/store';
import Layout from '@/modules/shared/Layout';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '@stripe/stripe-js';
import { Toaster } from 'sonner';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingOverlay from '@/components/ui/loading-overlay';
import Router from 'next/router';

import {
  DefaultOptions,
  HttpLink,
  ApolloLink,
  ApolloClient,
  concat,
  InMemoryCache,
} from '@apollo/client';
import FBPixelScript from '@/components/Facebook/FBPixelScript';
import FBPixelProvider from '@/components/Facebook/FBPixelProvider';
import '@/styles/globals.css';

// Load Montserrat Font
const montserrat = Montserrat({
  subsets: ['latin'],
  variable: '--font-montserrat',
});

type globalPropsType = {
  metadata: Global;
  headerNav: NavigationItem[];
  footerNav: NavigationItem[];
};
type AppOwnProps = { globalProps: globalPropsType };

let globalPropsCache: globalPropsType;
export default function MyApp({
  globalProps,
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }> & AppOwnProps) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    globalPropsCache = globalProps;
  }, [globalProps]);
  Router.events.on('routeChangeStart', () => {
    setLoading(true);
  });
  Router.events.on('routeChangeComplete', () => {
    setLoading(false);
  });

  const { metadata, footerNav, headerNav } = globalProps;

  return (
    <>
      <style jsx global>{`
        html {
          --font-montserrat: ${montserrat.style.fontFamily};
          font-family: var(--font-montserrat, sans-serif);
        }
      `}</style>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <DefaultSeo
        titleTemplate={`%s | ${metadata?.metaTitleSuffix}`}
        title={`${metadata?.metaTitleSuffix}`}
        description={metadata?.meta?.description!}
        openGraph={{
          images:
            metadata &&
            Object.values(metadata?.meta?.shareImage?.data?.attributes?.formats).map(
              (image: any) => {
                return {
                  url: image.url,
                  width: image.width,
                  height: image.height,
                };
              }
            ),
        }}
        twitter={{
          cardType: metadata?.meta?.twitterCardType!,
          handle: metadata?.twitterUsername!,
        }}
      />
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}>
        <Toaster position="top-right" richColors expand visibleToasts={3} closeButton />
        <Provider store={store}>
          <SessionProvider session={session}>
            <GoogleAnalytics trackPageViews />
            <FBPixelScript />
            <FBPixelProvider>
              {loading && <LoadingOverlay />}
              <Layout headerNav={headerNav} footerNav={footerNav}>
                <Component {...pageProps} />
              </Layout>
            </FBPixelProvider>
          </SessionProvider>
        </Provider>
      </GoogleReCaptchaProvider>
    </>
  );
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  context?.ctx?.res?.setHeader('Cache-Control', 'public, s-maxage=119, stale-while-revalidate=239');
  const ctx = await App.getInitialProps(context);
  if (globalPropsCache) {
    return {
      ...ctx,
      globalProps: globalPropsCache,
    };
  }
  if (typeof window === 'undefined') {
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only', // Used for first execution
        errorPolicy: 'ignore',
      },
    };
    const httpLink = new HttpLink({ uri: `${process.env.STRAPI_URL}/graphql` });
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: `Bearer ${process.env.STRAPI_API_KEY}`,
        },
      });
      return forward(operation);
    });

    const apolloCon = new ApolloClient({
      link: concat(authMiddleware, httpLink),
      cache: new InMemoryCache(),
      defaultOptions,
      ssrMode: typeof window === 'undefined',
    });

    const { data } = await apolloCon.query({
      query: GET_GLOBAL_CONFIG,
      variables: {
        footer: 'footer',
        header: 'main-navigation',
      },
    });

    const globalProps = {
      metadata: data.global.data.attributes,
      headerNav: data.header,
      footerNav: data.footer,
    };

    return {
      ...ctx,
      globalProps,
    };
  }
  // @ts-ignore
  return { ...ctx, globalProps: {} };
};
