import { dateFutureByMins, formatToCurrency } from '@/utils/helpers';
import Link from 'next/link';
import React from 'react';
import ProgressBar from './ProgressBar';
import { format, parseISO } from 'date-fns';
import { GiReceiveMoney } from 'react-icons/gi';
import { Badge } from '../ui/badge';

type FundraiserTeamProps = {
  rank?: number;
  teamName?: string;
  name?: string;
  targetAmount: number | undefined;
  amountRaised: number | undefined;
  slug?: string;
  endDate?: string;
  showEnded?: boolean;
};

const FundraiserTeam = ({
  rank,
  teamName,
  name,
  targetAmount,
  amountRaised,
  slug,
  endDate,
  showEnded,
}: FundraiserTeamProps) => {
  const pageEnded = dateFutureByMins(endDate || '');

  return (
    <div className="flex items-center space-x-4">
      {rank && <div className="flex-shrink-0 text-lg w-4 md:w-12">{rank}</div>}
      <div className="flex-1">
        <h3 className="flex text-xl font-bold mb-1 gap-3 items-center">
          {slug ? (
            <Link href={slug} className="text-yellow-500 hover:underline">
              {teamName}
            </Link>
          ) : (
            teamName
          )}
          <div className="flex items-center text-sm gap-1">
            <GiReceiveMoney />
            <span className="text-md font-normal">{formatToCurrency(amountRaised || '0')}</span>
          </div>
          {pageEnded && endDate && (
            <Badge variant="destructive" size="small" inverse>
              Ended {format(parseISO(endDate!), 'dd MMM yy')}
            </Badge>
          )}
          {!pageEnded && endDate && (
            <Badge variant="outline" size="small" inverse>
              Ending {format(parseISO(endDate!), 'dd MMM yy')}
            </Badge>
          )}
        </h3>

        <p className="text-sm text-gray-600 mb-1">{name}</p>

        <ProgressBar targetAmount={targetAmount} amountRaised={amountRaised} />
      </div>
    </div>
  );
};

export default FundraiserTeam;
