import React from 'react';
import { LoadingSpinner } from './loading-spinner';

type Props = {
  text?: string;
};

const LoadingOverlay = ({ text }: Props) => {
  return (
    <div>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80 backdrop-blur-sm">
        <div className="flex flex-col items-center space-y-4">
          <div className="h-12 w-12 animate-spin text-gray-300" />
          <LoadingSpinner circleColor="#4caf50" />
          <p className="text-gray-300 text-xl">{text ? text : 'Loading...'}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
