import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ComponentGlobalFundraisingOption } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { Button } from '../ui/button';
import { Card, CardContent, CardFooter } from '@/components/ui/card';

interface cardProps extends Omit<ComponentGlobalFundraisingOption, 'id'> {
  showBorder?: boolean;
}

const Cards = ({
  title,
  description,
  image,
  btnText,
  btnUrl,
  showBorder = true,
  showBtn = true,
}: cardProps) => {
  const imageUrl = getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.small)?.url;

  return (
    <Card showBorder={showBorder} className="flex flex-col h-full">
      <CardContent className="flex-grow space-y-3">
        {imageUrl && (
          <Image
            src={imageUrl}
            width={500}
            height={200}
            alt={title!}
            style={{ objectFit: 'contain' }}
          />
        )}
        <p className="text-black">{title}</p>
        <p className="text-sm text-gray-500">{description}</p>
      </CardContent>
      <CardFooter>
        {showBtn && (
          <Button asChild fullWidth>
            <Link href={{ pathname: btnUrl }}>{btnText}</Link>
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default Cards;
