import React from 'react';
import { StripeDonation } from '@/redux/slices/types';
import CheckoutBasket from '../../../components/CheckoutBasket';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setIsSidebarOpen } from '../../../redux/slices/donationSlice';
import { checkIfIntervalsMix, formatToCurrency, getTotalDonations } from '../../../utils/helpers';
import { Button } from '@/components/ui/button';
import router from 'next/router';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
import DonationErrors from './DonationErrors';

const CheckoutDrawer = () => {
  const isSidebarOpen = useAppSelector((state) => state.donation.sidebar);
  const { donations } = useAppSelector((state) => state.donation);
  const dispatch = useAppDispatch();

  const futureItems = donations.filter((donation: StripeDonation) => donation.startDate).length;

  const isProblem = donations && donations.length > 0 && checkIfIntervalsMix(donations);

  const isFutureMixedWithOthers = futureItems === 1 ? donations.length > 1 : false;

  const hasNone = donations && donations.length === 0;

  return (
    <Dialog
      open={isSidebarOpen}
      onOpenChange={(open) => !open && dispatch(setIsSidebarOpen(false))}
    >
      <DialogContent className="max-h-[90dvh] overflow-y-auto max-w-xl gap-2 md:gap-3">
        <DialogHeader>
          <DialogTitle>Your Donations</DialogTitle>
          <DialogDescription className="sr-only">
            Review your donation selections before proceeding to checkout.
          </DialogDescription>
        </DialogHeader>

        <DonationErrors
          isProblem={isProblem}
          isFutureMixedWithOthers={isFutureMixedWithOthers}
          futureItems={futureItems}
        />

        <CheckoutBasket />

        <Button
          size="xl"
          fullWidth
          disabled={isProblem || hasNone || isFutureMixedWithOthers || futureItems >= 2}
          onClick={() => {
            router.push('/account/guest');
            dispatch(setIsSidebarOpen(false));
          }}
        >
          Checkout{' '}
          {donations && donations.length > 0 && getTotalDonations(donations) > 0
            ? `${formatToCurrency(getTotalDonations(donations))}`
            : ''}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutDrawer;
