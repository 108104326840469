import React from 'react';

const TotalDonations = ({
  amountRaised,
  targetAmount,
}: {
  amountRaised: number;
  targetAmount: number;
}) => {
  const percentage =
    amountRaised && targetAmount && Math.round((amountRaised / targetAmount) * 100);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-6">
      <div className="p-4 bg-primary shadow rounded">
        <p className="text-sm">Amount Raised</p>
        <p className="font-bold">
          £
          {amountRaised.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>

      <div className="p-4 bg-primary shadow rounded">
        <p className="text-sm">Target</p>
        <p className="font-bold">
          £
          {targetAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
    </div>
  );
};

export default TotalDonations;
