import React, { useEffect, useState } from 'react';
import { useForm, hasLength } from '@mantine/form';
import { useSession, signIn } from 'next-auth/react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import CheckoutAccount from './CheckoutAccount';
import { CheckoutModalType } from './CheckoutModalType';
import CheckoutGuest from './CheckoutGuest';
import CheckoutPreferences from './CheckoutPreferences';
import CheckoutForm from './CheckoutForm';
import { ButtonGroup } from '../ui/button-group';
import { validateEmail, validatePassword } from '@/utils/validationUtils';

type CheckoutModalProps = {
  opened: boolean;
  close: () => void;
};

const CheckoutModal = ({ opened, close }: CheckoutModalProps) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { data, status } = useSession();
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (status === 'authenticated') {
      setActive(2);
      if (data?.user?.email) form.setFieldValue('email', data?.user?.email);
    }
  }, [status]);

  const form = useForm<CheckoutModalType>({
    validateInputOnChange: ['email'],
    initialValues: {
      guestCheckout: false,
      email: '',
      password: '',
      giftaid: false,
      keepInTouch: false,
    },
    validate: {
      email: validateEmail,
      password: validatePassword,
    },
  });

  const validateEmailAndNext = () => {
    form.validateField('email');
    if (form.isValid('email')) {
      nextStep();
    }
  };

  const guestCheckout = () => setActive(1);

  const logIn = async () => {
    form.validateField('email');
    form.validateField('password');
    if (!form.isValid('email') || !form.isValid('password')) {
      return;
    }
    setErrorMsg('');
    signIn('credentials', {
      email: form.values.email,
      password: form.values.password,
      redirect: false,
    }).then((res) => {
      if (res?.ok) {
        toast.success('You have been logged in!');
        setActive(2);
      } else if (res && !res.ok) {
        setErrorMsg(res.error!);
        toast.error(res.error);
      }
    });
  };

  const nextStep = () => {
    setActive((current) => (current < 4 ? current + 1 : current));
  };

  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return (
    <Dialog open={opened} onOpenChange={close}>
      <DialogContent className="max-h-[90dvh] overflow-y-auto max-w-xl">
        <DialogHeader>
          <DialogTitle>Checkout</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          {active === 0 && (
            <CheckoutAccount goToGuestStep={guestCheckout} form={form} errorMsg={errorMsg} />
          )}
          {active === 1 && <CheckoutGuest form={form} />}
          {active === 2 && <CheckoutPreferences form={form} />}
          {active === 3 && (
            <CheckoutForm form={form} prevStep={prevStep} close={close} nextStep={nextStep} />
          )}
        </div>

        {active !== 3 && (
          <div className="flex justify-between mt-6">
            <Button variant="outline" onClick={close}>
              Cancel
            </Button>
            <ButtonGroup>
              {active > 0 && status !== 'authenticated' && (
                <Button variant="outline" onClick={prevStep}>
                  Back
                </Button>
              )}
              {active === 0 && <Button onClick={logIn}>Sign In</Button>}
              {(active === 1 || active === 2) && (
                <Button onClick={validateEmailAndNext}>Next</Button>
              )}
              {active === 3 && <Button onClick={nextStep}>Finish</Button>}
            </ButtonGroup>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutModal;
