import React from 'react';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { ComponentGlobalButtonWithText } from 'types/cms';
import { getButtonVariant } from '@/utils/blockUtils';

const BtnWithText = (item: ComponentGlobalButtonWithText) => {
  return (
    <div className="container my-10 flex flex-col items-center text-center">
      <div className="mb-6">
        <div dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
      </div>
      <Link href={{ pathname: item?.btnUrl }} passHref>
        <Button size="lg" variant={getButtonVariant(item?.btnColour ?? undefined)}>
          {item?.btnText}
        </Button>
      </Link>
    </div>
  );
};

export default BtnWithText;
