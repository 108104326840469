import React, { useState } from 'react';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useAppSelector } from '@/redux/hooks';
import { StripeDonation } from '@/redux/slices/types';
import { UseFormReturnType } from '@mantine/form';
import { CheckoutModalType } from './CheckoutModalType';
import { useSession } from 'next-auth/react';
import { ButtonGroup } from '../ui/button-group';
import { Button } from '../ui/button';
import { Alert } from '@/components/ui/alert';

function CheckoutPaymentDetails({
  form,
  close,
  nextStep,
  prevStep,
}: {
  form: UseFormReturnType<CheckoutModalType>;
  close: () => void;
  nextStep: () => void;
  prevStep: () => void;
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const { data } = useSession();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const { donations } = useAppSelector((state) => state.donation);
  const userState = useAppSelector((state) => state.user);
  const subscriptionItem = donations.filter((donation: StripeDonation) => donation.startDate)[0];

  const handleError = (error: StripeError) => {
    setLoading(false);
    setErrorMessage(error.message!);
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const addressElement = elements?.getElement('address');
    const address = await addressElement?.getValue();
    if (!address?.complete) {
      setErrorMessage('Address incomplete');
      return;
    }
    setErrorMessage('');
    setLoading(true);

    const customerInformation = {
      email: form.values.email,
      firstName: address?.value.firstName,
      lastName: address?.value.lastName,
      telephone: address?.value.phone,
      address1: address?.value.address.line1,
      address2: address?.value.address.line2,
      address3: address?.value.address.city,
      ukpostcode: address?.value.address.postal_code,
      country: address?.value.address.country,
    };

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const res = await fetch('/api/create-setup-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscriptionItem,
        customerInformation,
        userState,
        metadata: {
          keepInTouch: JSON.stringify(form.values.keepInTouch),
          giftaid: form.values.giftaid,
        },
      }),
    });

    const { type, clientSecret, subscriptionId } = await res.json();
    const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

    const { error } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url:
          process.env.NODE_ENV === 'development'
            ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/checkout/thank-you?subId=${subscriptionId}`
            : `https://${window.location.hostname}/checkout/thank-you?subId=${subscriptionId}`,
      },
    });

    if (error) {
      handleError(error);
    }
  };

  return (
    <div>
      <h3 className="text-lg font-bold mb-4">Billing Details</h3>
      <AddressElement
        options={{
          defaultValues: {
            firstName: data?.token.FirstName,
            lastName: data?.token.LastName,
            phone: data?.token.phone?.toString(),
            address: {
              line1: data?.token.address1,
              line2: data?.token.address2,
              city: data?.token.city,
              postal_code: data?.token.postcode,
              country: 'GB',
            },
          },
          display: { name: 'split' },
          fields: { phone: 'always' },
          mode: 'billing',
        }}
      />
      <h3 className="text-lg font-bold mt-6 mb-4">Card Details</h3>
      <PaymentElement
        onLoadError={(error) => {
          setErrorMessage(
            'Sorry this browser/app is not supported to take Stripe payments. Please try a new tab or browser.'
          );
          throw new Error(`Stripe didn't load ${error}`);
        }}
      />
      {errorMessage && (
        <Alert variant="destructive" className="mt-4">
          {errorMessage}
        </Alert>
      )}

      <div className="flex justify-between mt-6">
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <ButtonGroup>
          <Button variant="outline" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={handleSubmit} disabled={loading}>
            {loading ? 'Processing...' : 'Confirm'}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default CheckoutPaymentDetails;
