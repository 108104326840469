import React from 'react';
import { NavigationItem } from 'types/cms';
import Link from 'next/link';
import { signOut } from 'next-auth/react';
import { Session } from 'next-auth';
import { cn } from '@/lib/utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Sheet, SheetContent, SheetTitle } from '@/components/ui/sheet';
import { toast } from 'sonner';

type DrawerContentProps = {
  nav: NavigationItem[];
  data: Session | null;
  onClose: () => void;
  router: any;
};

const DrawerContent: React.FC<DrawerContentProps> = ({ nav, data, onClose, router }) => {
  return (
    <Sheet open={true} onOpenChange={onClose}>
      <SheetTitle className="sr-only">Mobile Menu</SheetTitle>
      <SheetContent className="bg-yellow-400 p-0">
        <div className="mt-16 px-3">
          {!data ? (
            <button
              onClick={() => {
                onClose();
                router.push('/account/login');
              }}
              className="text-base font-medium uppercase mb-2 text-black"
            >
              Login
            </button>
          ) : (
            <div className="flex flex-col items-start space-y-4 font-medium mb-2">
              <button
                onClick={() => {
                  onClose();
                  toast.success('You have been logged out!', { duration: 3000 });
                  signOut({ callbackUrl: '/' });
                }}
                className="text-base font-medium uppercase text-black"
              >
                Log out
              </button>
              <button
                onClick={() => {
                  onClose();
                  router.push('/dashboard');
                }}
                className="text-base font-medium uppercase text-black"
              >
                My Account
              </button>
            </div>
          )}
        </div>
        <Accordion type="single" collapsible>
          {nav?.map((firstLevel) => (
            <AccordionItem
              key={firstLevel.id}
              value={firstLevel.title}
              className="data-[state=open]:bg-yellow-500 px-3"
            >
              <AccordionTrigger className="uppercase text-base text-black">
                {firstLevel.title}
              </AccordionTrigger>
              <AccordionContent className="space-y-1">
                {firstLevel.items?.length! > 0 &&
                  firstLevel.items?.map((item) => (
                    <div key={item?.title}>
                      <Link
                        onClickCapture={() => onClose()}
                        href={item?.path!}
                        className={cn('block uppercase text-black')}
                      >
                        {item?.title}
                      </Link>
                    </div>
                  ))}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </SheetContent>
    </Sheet>
  );
};

export default DrawerContent;
