import React from 'react';
import Link from 'next/link';
import { FiAlertCircle } from 'react-icons/fi';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Alert } from '@/components/ui/alert';
import { Label } from '@/components/ui/label';
import { UseFormReturnType } from '@mantine/form';
import { CheckoutModalType } from './CheckoutModalType';
import LabeledSeparator from '../ui/label-seperator';
import { ErrorDisplay } from '../ui/error-display';

type CheckoutAccountProps = {
  form: UseFormReturnType<CheckoutModalType>;
  errorMsg: string;
  goToGuestStep: () => void;
};

const CheckoutAccount: React.FC<CheckoutAccountProps> = ({ form, errorMsg, goToGuestStep }) => {
  return (
    <div className="container mx-auto space-y-6">
      <div>
        <Button onClick={goToGuestStep} className="w-full" type="submit">
          Guest Checkout
        </Button>
        <p className="text-center mt-2 text-sm font-semibold text-gray-600">
          You can create an account after Guest Checkout
        </p>
      </div>

      <div>
        <LabeledSeparator label="OR sign in if you have an account" />

        {errorMsg && (
          <Alert variant="destructive" className="mb-4">
            <FiAlertCircle className="mr-2 h-5 w-5" />
            <span>{errorMsg}</span>
          </Alert>
        )}

        <div className="space-y-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="you@gmail.com"
              required
              {...form.getInputProps('email')}
            />
            <ErrorDisplay error={form.errors.email} />
          </div>
          <div>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              placeholder="Your password"
              required
              {...form.getInputProps('password')}
            />
            <ErrorDisplay error={form.errors.password} />
          </div>
          <div className="flex justify-between items-center mt-4">
            <Link href="/account/reset-password" className="text-sm text-blue-600 hover:underline">
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutAccount;
