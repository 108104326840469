import React from 'react';
import {
  ComponentDonationTeams,
  FundraisePageEntity,
  FundraiseTeamCampaignEntity,
  Maybe,
} from 'types/cms';
import LeaderboardGroup from './LeaderboardGroup';
import TotalDonations from './TotalDonations';

const LeaderboardGroupContainer = ({ teams }: ComponentDonationTeams) => {
  // Function to calculate total donations for a team
  const calculateTeamTotalDonations = (team: Maybe<FundraiseTeamCampaignEntity>) => {
    let totalDonations = 0;
    // Add team-level donations
    team?.attributes?.campaign_page?.data?.attributes?.donations?.data.forEach((donation) => {
      totalDonations +=
        (donation.attributes?.amount || 0) * (donation.attributes?.interval === 'month' ? 12 : 1) ||
        0;
    });
    team?.attributes?.member_pages?.data.forEach((memberPage) => {
      memberPage?.attributes?.donations?.data.forEach((donation) => {
        totalDonations +=
          (donation.attributes?.amount || 0) *
            (donation.attributes?.interval === 'month' ? 12 : 1) || 0;
      });
    });
    return totalDonations;
  };

  // Function to calculate total donations for an individual member page
  const calculateMemberTotalDonations = (memberPage: FundraisePageEntity) => {
    let totalDonations = 0;
    memberPage.attributes?.donations?.data.forEach((donation) => {
      totalDonations +=
        (donation.attributes?.amount || 0) * (donation.attributes?.interval === 'month' ? 12 : 1) ||
        0;
    });
    return totalDonations;
  };

  // Sorting teams based on total donations
  const sortedTeams = (teams?.data || [])
    .filter((team): team is FundraiseTeamCampaignEntity => team !== undefined)
    .sort((a, b) => {
      const totalDonationsA = calculateTeamTotalDonations(a as Maybe<FundraiseTeamCampaignEntity>);
      const totalDonationsB = calculateTeamTotalDonations(b as Maybe<FundraiseTeamCampaignEntity>);
      return totalDonationsB - totalDonationsA;
    })
    .filter((team) => {
      if (team.attributes?.campaign_page?.data?.attributes?.publishedAt == null) {
        if (team.attributes?.campaign_page?.data?.attributes?.donations?.data?.length! != 0) {
          return true;
        }
        return false;
      }
      return true;
    });

  // Sorting member pages based on total donations
  const sortedMemberPages: FundraisePageEntity[] = (teams?.data || [])
    .reduce((accumulator: FundraisePageEntity[], team) => {
      if (team.attributes?.member_pages?.data) {
        team.attributes?.member_pages?.data.forEach((memberPage: FundraisePageEntity) => {
          const extendedMemberPage = {
            ...memberPage,
            teamName: team.attributes?.campaign_page?.data?.attributes?.name || '',
          } as FundraisePageEntity;
          accumulator.push(extendedMemberPage);
        });
      }
      return accumulator;
    }, [])
    .sort((a, b) => {
      const totalDonationsA = calculateMemberTotalDonations(a);
      const totalDonationsB = calculateMemberTotalDonations(b);
      return totalDonationsB - totalDonationsA;
    });
  const totalDonationCalc = (teams?.data || []).reduce((total, team) => {
    if (team !== undefined) {
      total += calculateTeamTotalDonations(team as Maybe<FundraiseTeamCampaignEntity>);
    }
    return total;
  }, 0);

  const totalGoalAmount = (teams?.data || []).reduce((total, team) => {
    if (team !== undefined) {
      total += team?.attributes?.campaign_page?.data?.attributes?.goal || 0;
      //   team?.attributes?.member_pages?.data.forEach((memberPage) => {
      //     total += memberPage?.attributes?.goal || 0;
      //   });
    }
    return total;
  }, 0);

  return (
    <div className="container">
      <TotalDonations amountRaised={totalDonationCalc} targetAmount={totalGoalAmount} />
      <LeaderboardGroup
        sortedTeams={sortedTeams}
        sortedMemberPages={sortedMemberPages}
        calculateTeamTotalDonations={calculateTeamTotalDonations}
        calculateMemberTotalDonations={calculateMemberTotalDonations}
      />
    </div>
  );
};

export default LeaderboardGroupContainer;
