import React from 'react';
import { Alert } from '@/components/ui/alert';

type DonationErrorsProps = {
  isProblem: boolean;
  isFutureMixedWithOthers: boolean;
  futureItems: number;
};

const DonationErrors: React.FC<DonationErrorsProps> = ({
  isProblem,
  isFutureMixedWithOthers,
  futureItems,
}) => {
  if (!isProblem && !isFutureMixedWithOthers && futureItems < 2) {
    return null;
  }

  return (
    <div className="space-y-4 mb-4">
      {isProblem && (
        <Alert variant="destructive">
          <strong>Error:</strong> You cannot mix daily, weekly, monthly, or yearly donation
          frequencies currently. Please remove one.
        </Alert>
      )}
      {futureItems >= 2 && (
        <Alert variant="destructive">
          <strong>Error:</strong> Currently, you can only checkout with one regular subscription
          that starts in the future. Please remove one.
        </Alert>
      )}
      {isFutureMixedWithOthers && (
        <Alert variant="destructive">
          <strong>Error:</strong> You cannot checkout a regular subscription that starts in the
          future with a one-time donation. Please remove one.
        </Alert>
      )}
    </div>
  );
};

export default DonationErrors;
