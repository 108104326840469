import Embed from 'react-embed';
import React, { useEffect, useState } from 'react';

export default function EmbedContent({
  link,
  noContainer,
}: {
  link: string | null | undefined;
  noContainer?: boolean;
}) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!link) {
    return null;
  }

  const embedComponent = (
    <div className="block rounded-md overflow-hidden w-full">
      <Embed url={link} />
    </div>
  );

  if (noContainer) {
    return isClient ? embedComponent : null;
  }

  return <div className="container my-5">{isClient && embedComponent}</div>;
}
