import React from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { addZakahUplift, toggleCrossSell } from '@/redux/slices/donationSlice';
import { EnumIntention, StripeDonation } from '@/redux/slices/types';
import { formatToCurrency, getTotalDonations } from '@/utils/helpers';
import BasketItem from './BasketItem';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { BsInfoCircle } from 'react-icons/bs';
import { cn } from '@/lib/utils';

type Props = {};

const CheckoutBasket: React.FC<Props> = () => {
  const { donations, hasSadakahUplift, hasZakahUplift } = useAppSelector((state) => state.donation);
  const dispatch = useAppDispatch();

  const hasZakah =
    donations.filter((donation) => donation.intention === EnumIntention.Zakat).length > 0;
  const hasSadakah =
    donations.filter((donation) => donation.intention === EnumIntention.Sadaqa).length > 0;

  const handleCrossSell = (checked: boolean) => {
    dispatch(toggleCrossSell(checked));
  };

  const handleZakahUplift = (checked: boolean) => {
    dispatch(addZakahUplift(checked));
  };

  return (
    <>
      <TooltipProvider>
        <ScrollArea className="max-h-[30vh]">
          <div className="space-y-2">
            {donations &&
              donations.length > 0 &&
              donations.map((data: StripeDonation) => <BasketItem key={data.id} {...data} />)}
          </div>
        </ScrollArea>

        <div className={cn((hasSadakah || hasZakah) && 'bg-gray-100 p-3 md:p-4 space-y-2')}>
          {hasSadakah && (
            <div className="items-center flex space-x-2">
              <Checkbox
                id="sadakah-uplift"
                checked={hasSadakahUplift}
                onCheckedChange={(checked) => handleCrossSell(checked as boolean)}
              />
              <div>
                <div className="flex items-center gap-2">
                  <Label htmlFor="sadakah-uplift">YES ADD £5 SADAQAH</Label>

                  <Tooltip>
                    <TooltipTrigger
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <button type="button" className="flex items-center">
                        <BsInfoCircle className="text-gray-600 cursor-pointer" />
                      </button>
                    </TooltipTrigger>

                    <TooltipContent className="max-w-sm">
                      Cover processing fees for your donation and costs related to marketing so we
                      can reach more people with the campaign inshaAllah.
                    </TooltipContent>
                  </Tooltip>
                </div>
                <p className="text-xs text-muted-foreground p-0 mb-0">
                  I&apos;d like to help cover processing fees and marketing costs!
                </p>
              </div>
            </div>
          )}

          {hasZakah && (
            <div className="flex justify-between items-center space-x-2">
              <Checkbox
                id="zakah-uplift"
                checked={hasZakahUplift}
                onCheckedChange={(checked: boolean) => handleZakahUplift(checked as boolean)}
              />
              <Label htmlFor="zakah-uplift">
                I want to add 12.5% sadaqah to cover costs relating to the distribution of my Zakah.
              </Label>
            </div>
          )}
        </div>

        {/* <div className="flex justify-between items-center donation-box">
          <p className="font-semibold mb-0">Total</p>
          <p className="font-semibold mb-0">
            {donations && donations.length > 0
              ? formatToCurrency(getTotalDonations(donations))
              : formatToCurrency(0)}
          </p>
        </div> */}
      </TooltipProvider>
    </>
  );
};

export default CheckoutBasket;
