import React, { useState } from 'react';
import Image from 'next/image';
import { useForm } from '@mantine/form';
import fetch from 'isomorphic-unfetch';
import { ComponentGlobalSubscribe } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';

interface subscribeCtaType extends Omit<ComponentGlobalSubscribe, 'id'> {}

const SubscribeCta: React.FC<subscribeCtaType> = ({ title, btnText, description, image }) => {
  const [message, setMessage] = useState('');
  const form = useForm({
    initialValues: {
      email: '',
    },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const handleSubmit = (values: { email: string }) => {
    fetch('/api/subscribe-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: values.email }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 400) {
          throw new Error(responseJson.message);
        }
        setMessage('Thank you for subscribing!');
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        setMessage(err.message);
      });
  };

  return (
    <div className="mb-12 py-8 text-left">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
          <div className="md:order-2">
            <h1>{title}</h1>
            <p className="text-sm text-gray-500 mb-6">{description}</p>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <div className="mb-3">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Your email"
                  {...form.getInputProps('email')}
                />
              </div>
              <p className="text-sm text-gray-500 my-5">{message}</p>
              <Button type="submit" className="uppercase w-full py-2 px-4 rounded-md">
                {btnText}
              </Button>
            </form>
          </div>
          <div className="hidden md:block justify-self-center">
            <Image
              src={getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.medium)?.url}
              className="max-w-full"
              alt="subscribe image"
              width="500"
              height="100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeCta;
