import React from 'react';
import { Button } from '@/components/ui/button';
import { ButtonGroup } from '@/components/ui/button-group';
import { useMediaQuery } from 'usehooks-ts';

type PaymentToggleButtonGroupProps = {
  isMix: boolean;
  isOnlySingle: boolean;
  isOnlyRegular: boolean;
  isRegular: boolean;
  onlyOneRegularOptionAvailable: boolean;
  regularFreqList: string[];
  displayNameForFrequency: Record<string, string>;
  handlePaymentRecurring: (value: boolean) => void;
};

const PaymentToggleButtonGroup: React.FC<PaymentToggleButtonGroupProps> = ({
  isMix,
  isOnlySingle,
  isOnlyRegular,
  isRegular,
  onlyOneRegularOptionAvailable,
  regularFreqList,
  displayNameForFrequency,
  handlePaymentRecurring,
}) => {
  const isXsBp = useMediaQuery('(min-width: 900px)');
  return (
    <ButtonGroup>
      {(isMix || isOnlySingle) && (
        <Button
          size={isXsBp ? 'xl' : 'lg'}
          className="flex-1"
          variant={!isRegular ? 'default' : 'secondary'}
          onClick={() => handlePaymentRecurring(false)}
        >
          Give Once
        </Button>
      )}
      {(isMix || isOnlyRegular) && (
        <Button
          size={isXsBp ? 'xl' : 'lg'}
          className="flex-1"
          variant={isRegular || isOnlyRegular ? 'default' : 'secondary'}
          onClick={() => handlePaymentRecurring(true)}
        >
          {onlyOneRegularOptionAvailable
            ? displayNameForFrequency[regularFreqList[0]!] ?? 'Regular'
            : 'Regular'}
        </Button>
      )}
    </ButtonGroup>
  );
};

export default PaymentToggleButtonGroup;
