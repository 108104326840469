import React, { useEffect, useState } from 'react';
import { ComponentWrite2GovWriteToGov } from 'types/cms';
import { TransformedValues, useForm } from '@mantine/form';
import { WriteToGovForm } from 'types/otherTypes';
import { toast } from 'sonner';
import { Progress } from '@/components/ui/progress';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { LoadingSpinner } from '@/components/ui/loading-spinner';
import { Button } from '@/components/ui/button';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

export default function WriteToGov({ write_to_gov }: ComponentWrite2GovWriteToGov) {
  if (!write_to_gov?.data?.id) {
    return null;
  }
  const [emailsSent, setEmailsSent] = useState(0);
  // const [currentTarget, setCurrentTarget] = useState(500);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [step2, setStep2] = useState(false);
  const addressTo = write_to_gov.data.attributes?.emails?.map((email) => email?.name).join(', ');
  // set current target to the nearest 1000 from emailsSent
  const currentTarget = Math.ceil((emailsSent + 1000) / 1000) * 1000;
  const form = useForm<WriteToGovForm>({
    initialValues: {
      entryId: write_to_gov?.data?.id || '',
      firstName: '',
      lastName: '',
      postCode: '',
      phone: '',
      email: '',
      addressLine1: '',
      subject: write_to_gov.data.attributes?.subject || '',
      body: write_to_gov.data.attributes?.body || '',
      contactByEmail: 'yes',
    },
    validate: {
      email: (value: string) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email'),
      firstName: (value: string) => (value.trim().length > 2 ? null : 'First name is required'),
      lastName: (value: string) => (value.trim().length > 2 ? null : 'Last name is required'),
      postCode: (value: string) => (value.trim().length > 1 ? null : 'Post code is required'),
    },
  });

  useEffect(() => {
    const fetchEmailsSent = async () => {
      const response = await fetch('/api/get-write-to-gov-emails-sent', {
        method: 'POST',
        body: JSON.stringify({ id: write_to_gov?.data?.id }),
      });
      const data = await response.json();
      setEmailsSent(data);
    };
    if (write_to_gov.data?.attributes?.showProgressBar) {
      fetchEmailsSent();
    }
  }, [write_to_gov]);
  const ProgressComponent = () => {
    if (write_to_gov.data?.attributes?.showProgressBar) {
      return (
        <>
          <Progress value={(emailsSent / currentTarget) * 100} />
          <p>
            <strong>{emailsSent}</strong> emails have been sent. Will you help us reach{' '}
            <strong>{currentTarget}</strong>?
          </p>
        </>
      );
    }
    return null;
  };
  const handleSubmit = async (values: TransformedValues<typeof form>) => {
    setLoading(true);
    try {
      const response = await fetch('/api/send-write-to-gov', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values }),
      });
      const data: { status: string; message: string } = await response.json();

      if (!response.ok) {
        throw data;
      }
      if (data.status === 'ok') {
        setLoading(false);
        setComplete(true);
        toast.info('Email(s) sent');
      } else {
        setLoading(false);
        throw data.message || `Please check your email and try again`;
      }
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
      console.error(error);
    }
  };
  const goToStep2 = () => {
    if (!form.isValid()) {
      form.validate();
      return;
    }
    form.setFieldValue(
      'body',
      `${form.values.body}

${form.values.firstName} ${form.values.lastName}
${form.values.addressLine1}
${form.values.postCode}
${form.values.phone} ${form.values.email}

I sent this email via the Human Aid & Advocacy website. Replies will go to my personal email address.`
    );
    setStep2(true);
  };
  return (
    <div className="container max-w-lg my-2">
      <Card className="p-4">
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {complete && <h2>Thank you for your submission</h2>}
          {!complete && step2 && (
            <div className="space-y-4">
              <p>
                <strong>
                  We've written a template email that you can send, but why not add a personal touch
                  to give it more impact?
                </strong>{' '}
                Adding a sentence or two at the beginning and end is a great place to start.
              </p>
              <div>
                <Label htmlFor="subject">Subject</Label>
                <Input id="subject" minLength={1} {...form.getInputProps('subject')} required />
              </div>
              <p>Dear {addressTo}</p>
              <div>
                <Label htmlFor="body">Message</Label>
                <Textarea {...form.getInputProps('body')} id="body" rows={10} required />
              </div>
              {loading && <LoadingSpinner />}
              <Button disabled={loading} type="submit">
                Send
              </Button>
            </div>
          )}
          {!complete && !step2 && (
            <div className="space-y-4">
              <ProgressComponent />
              <p>Enter your details. You'll be able to edit a template email on the next step.</p>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="wtg_firstName">First Name</Label>
                  <Input id="wtg_firstName" required {...form.getInputProps('firstName')} />
                </div>

                <div>
                  <Label htmlFor="wtg_lastName">Last Name</Label>
                  <Input id="wtg_lastName" required {...form.getInputProps('lastName')} />
                </div>

                <div>
                  <Label htmlFor="wtg_email">Email</Label>
                  <Input required id="wtg_email" type="email" {...form.getInputProps('email')} />
                </div>

                <div>
                  <Label>Update me on this and future campaigns</Label>
                  <RadioGroup
                    onValueChange={form.getInputProps('contactByEmail').onChange}
                    {...form.getInputProps('contactByEmail')}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="no" id="no" />
                      <Label htmlFor="no">No</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="yes" id="no" />
                      <Label htmlFor="yes">Yes</Label>
                    </div>
                  </RadioGroup>
                </div>
                <div>
                  <Label htmlFor="addressLine1">First line of address</Label>
                  <Input required {...form.getInputProps('addressLine1')} />
                </div>
                <div>
                  <Label htmlFor="postCode">Post Code</Label>
                  <Input required {...form.getInputProps('postCode')} />
                </div>
                <div>
                  <Label htmlFor="phone">Phone</Label>
                  <Input {...form.getInputProps('phone')} />
                </div>
              </div>
              <Button onClick={goToStep2}>Next</Button>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}
