// frontend/src/utils/blockUtils.ts

export const convertColourToThemeColour = (colour?: string | null): string => {
  switch (colour) {
    case 'red':
      return 'bg-red-500';
    case 'black':
      return 'bg-black';
    case 'white':
      return 'bg-white';
    case 'yellow':
      return 'bg-yellow-400';
    default:
      return '';
  }
};

export const getButtonVariant = (color: string | undefined): any => {
  switch (color?.toLowerCase()) {
    case 'red':
      return 'destructive';
    case 'black':
      return 'black';
    default:
      return 'default';
  }
};

export const getTextColorForBg = (bgColour?: string | null): string => {
  switch (bgColour) {
    case 'red':
      return 'text-white';
    case 'black':
      return 'text-white';
    case 'yellow':
      return 'text-black';
    case 'white':
      return 'text-black';
    default:
      return 'text-black';
  }
};
