import React from 'react';
import { ComponentHeroHeroQuickDonateQuery } from 'types/cms';
import Hero from '.';
import useDonationQuery from '@/hooks/useDonationQuery';
import AppealsSection from './AppealsSection';

const DonateQuery = ({
  DonationQuery: donationQuery,
  Hero: hero,
}: ComponentHeroHeroQuickDonateQuery) => {
  const { filters, query } = { ...donationQuery };

  if (!query) {
    console.error('Donation query is undefined or empty.');
    return null;
  }

  const { data, selectedAppeal, handleSelectChange } = useDonationQuery({
    query,
    filters,
  });

  const foundAppealsDataIndex =
    data?.appeals?.data.findIndex((el) => el?.attributes?.title === selectedAppeal) || 0;

  return (
    <>
      <Hero {...hero}>
        <div className="max-w-sm">
          <AppealsSection
            data={data}
            selectedAppeal={selectedAppeal}
            handleSelectChange={handleSelectChange}
            foundAppealsDataIndex={foundAppealsDataIndex}
          />
        </div>
      </Hero>
      <div className="container mx-auto mt-6 lg:mt-8">
        <div className="block sm:hidden mb-4">
          <AppealsSection
            data={data}
            selectedAppeal={selectedAppeal}
            handleSelectChange={handleSelectChange}
            foundAppealsDataIndex={foundAppealsDataIndex}
          />
        </div>
      </div>
    </>
  );
};

export default DonateQuery;
