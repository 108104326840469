import { useState } from 'react';
import { formatToCurrency } from '@/utils/helpers';
import { useAppDispatch } from '../../redux/hooks';
import { removeDonation } from '../../redux/slices/donationSlice';
import { StripeDonation } from '../../redux/slices/types';
import { LuTrash } from 'react-icons/lu';
import { cn } from '@/lib/utils';

const BasketItem = ({ interval, amount, intention, name, quantity, id }: StripeDonation) => {
  const dispatch = useAppDispatch();
  const [isRemoving, setIsRemoving] = useState(false);

  const handleDelete = () => {
    setIsRemoving(true);
    // Delay removal to allow animation to complete
    setTimeout(() => {
      dispatch(removeDonation(id));
    }, 300);
  };

  return (
    <div
      className={cn(
        'donation-box flex transition-all duration-300 ease-in-out',
        isRemoving ? 'opacity-0 translate-x-[-100%]' : 'opacity-100 translate-x-0'
      )}
    >
      <div className="w-1/2">
        <p className="mb-0">
          <span className="font-semibold">{name}</span>
          <br />
          <div className="text-xs md:text-sm">
            {intention}
            {intention && interval && ', '}
            {interval}
          </div>
        </p>
      </div>
      <div className="w-1/2 flex flex-col items-end">
        <p className="mb-0 font-extrabold">
          {quantity} x {formatToCurrency(amount!)}
        </p>
        <button onClick={handleDelete}>
          <LuTrash size="20" />
        </button>
      </div>
    </div>
  );
};

export default BasketItem;
