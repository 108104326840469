import { useEffect, useState } from 'react';
import { Query } from 'types/cms';

interface UseDonationQueryProps {
  query: string | undefined;
  filters?: any;
}

const useDonationQuery = ({ query, filters }: UseDonationQueryProps) => {
  const [data, setData] = useState<Query | null>(null);
  const [selectedAppeal, setSelectedAppeal] = useState<string>('');

  useEffect(() => {
    if (!query) {
      console.warn('Query is undefined or empty.');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch('/api/run-query', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query, filters }),
        });
        const result = await response.json();
        setData(result);

        if (result?.appeals?.data?.length > 0) {
          setSelectedAppeal(result.appeals.data[0]?.attributes?.title || '');
        }
      } catch (error) {
        console.error('Error fetching donation data:', error);
      }
    };

    fetchData();
  }, [query, filters]);

  const handleSelectChange = (value: string) => {
    setSelectedAppeal(value);
  };

  return { data, selectedAppeal, handleSelectChange };
};

export default useDonationQuery;
