import Link from 'next/link';
import React from 'react';
import { AppealEntity, ComponentAppealsFeaturedAppeals } from 'types/cms';
import Card from '../Cards';
import Carousel from '../Carousel';

const FeaturedAppeals: React.FC<ComponentAppealsFeaturedAppeals> = ({
  title,
  footerLinkHref,
  footerLinkText,
  appeals,
}: ComponentAppealsFeaturedAppeals) => {
  const carouselData =
    appeals &&
    appeals.data.length > 0 &&
    appeals?.data.map((appeal: AppealEntity) => {
      return {
        title: appeal.attributes?.title,
        description: appeal.attributes?.shortDescription,
        image: appeal.attributes?.image,
        btnText: 'Learn more',
        btnUrl: `/appeals/${appeal.attributes?.slug}`,
      };
    });

  return (
    <div className="container mt-4">
      <div className="my-4">
        <h3 className="mb-2 text-center">{title}</h3>

        {carouselData ? <Carousel data={carouselData} view={<Card />} /> : null}

        <div className="text-center mt-3">
          <Link
            passHref
            href={{ pathname: footerLinkHref }}
            className="text-blue-500 hover:underline hover:text-blue-700"
          >
            {footerLinkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedAppeals;
