import React from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { MdLogin, MdLogout, MdPersonOutline } from 'react-icons/md';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '../ui/button';

type Props = {};

const ProfileMenu = (props: Props) => {
  const router = useRouter();
  const { data } = useSession();

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <MdPersonOutline size={20} />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-48">
          {!data ? (
            <DropdownMenuItem
              onClick={() => router.push('/account/login')}
              className="flex items-center space-x-2"
            >
              <MdLogin size={20} />
              <span>Login</span>
            </DropdownMenuItem>
          ) : (
            <>
              <DropdownMenuItem
                onClick={() => {
                  toast.success('You have been logged out!');
                  signOut({ callbackUrl: '/' });
                }}
                className="flex items-center space-x-2"
              >
                <MdLogout size={20} />
                <span>Log out</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => router.push('/dashboard')}
                className="flex items-center space-x-2"
              >
                <MdPersonOutline size={20} />
                <span>Dashboard</span>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ProfileMenu;
