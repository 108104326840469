import React from 'react';
import Link from 'next/link';
import { ComponentGlobalSectionWithText } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import EmbedContent from '@/components/Blocks/EmbedContent';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { convertColourToThemeColour } from '@/utils/blockUtils';
import Image from 'next/image';

const MediaBlock = ({ videoLink, image, dividerBorder }: any) => {
  if (!videoLink && !image?.data?.attributes) return null; // No media block

  const imageUrl = getStrapiImage(image?.data?.attributes!, ImageSizeNameEnum.large)?.url;

  return (
    <div className={cn('flex items-center justify-center', dividerBorder && 'pt-6 md:pt-0')}>
      {videoLink ? (
        <EmbedContent link={videoLink} noContainer />
      ) : (
        imageUrl && (
          <div className="relative w-full max-w-[800px] h-auto">
            <Image
              src={imageUrl}
              alt={image?.data?.attributes?.alternativeText || 'Section Media'}
              width={800}
              height={450}
              placeholder="blur"
              blurDataURL={image?.data?.attributes?.placeholder || 'data:image/jpeg;base64,...'}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

const TextBlock = ({ text, btnUrl, btnText, bgColour, dividerBorder }: any) => {
  return (
    <div
      className={cn(
        'flex flex-col justify-center text-center md:text-left w-full mx-auto',
        dividerBorder
          ? 'md:border-l-4 border-yellow-500 md:pl-6 py-6 lg:py-0 max-w-3xl'
          : 'pt-6 lg:pt-0 md:px-6 max-w-2xl'
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: text || '' }} className="cms-content" />

      {btnUrl && (
        <div className="mt-6">
          <Button asChild size="xl" variant={bgColour !== 'yellow' ? 'default' : 'destructive'}>
            <Link href={{ pathname: btnUrl }}>{btnText}</Link>
          </Button>
        </div>
      )}
    </div>
  );
};

const SectionWithText = ({ section }: ComponentGlobalSectionWithText) => {
  return (
    <div>
      {section?.map((item) => {
        const hasMedia = item?.videoLink || item?.image?.data?.attributes;
        const isMediaOnRight = item?.showOnRight;
        const hasDividerBorder = item?.dividerBorder;

        return (
          <div
            key={item?.id}
            className={cn(
              convertColourToThemeColour(item?.bgColour),
              item?.bottomBorder && 'border-b-4 border-red-600',
              !hasDividerBorder && 'py-4 md:py-6 xl:py-8'
            )}
          >
            <div className={cn('container', hasDividerBorder)}>
              <div
                className={cn(
                  'grid',
                  item?.dividerBorder && 'gap-0',
                  hasMedia ? 'grid-cols-1 lg:grid-cols-2' : 'grid-cols-1 justify-items-center'
                )}
              >
                {isMediaOnRight ? (
                  <>
                    <div className="lg:order-last flex">
                      {hasMedia && (
                        <MediaBlock
                          videoLink={item?.videoLink}
                          image={item?.image}
                          dividerBorder={item?.dividerBorder}
                        />
                      )}
                    </div>
                    <TextBlock
                      text={item?.text}
                      btnUrl={item?.btnUrl}
                      btnText={item?.btnText}
                      bgColour={item?.bgColour}
                      dividerBorder={item?.dividerBorder}
                    />
                  </>
                ) : (
                  <>
                    {hasMedia && (
                      <MediaBlock
                        videoLink={item?.videoLink}
                        image={item?.image}
                        dividerBorder={item?.dividerBorder}
                      />
                    )}
                    <TextBlock
                      text={item?.text}
                      btnUrl={item?.btnUrl}
                      btnText={item?.btnText}
                      bgColour={item?.bgColour}
                      dividerBorder={item?.dividerBorder}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SectionWithText;
