import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Enum_Componentdonationquickdonateoptions_Frequency } from 'types/cms';

type FrequencySelectorProps = {
  isRegular: boolean;
  onlyOneRegularOptionAvailable: boolean;
  freq: string;
  regularFreqSelectList: { value: string; label: string }[];
  onFreqChange: (value: Enum_Componentdonationquickdonateoptions_Frequency) => void;
};

const FrequencySelector: React.FC<FrequencySelectorProps> = ({
  isRegular,
  onlyOneRegularOptionAvailable,
  freq,
  regularFreqSelectList,
  onFreqChange,
}) => {
  if (!isRegular || onlyOneRegularOptionAvailable) return null;

  return (
    <div>
      <Select
        value={freq}
        onValueChange={(value) => {
          onFreqChange(value as Enum_Componentdonationquickdonateoptions_Frequency);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select Frequency" />
        </SelectTrigger>
        <SelectContent>
          {regularFreqSelectList.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default FrequencySelector;
