import React from 'react';
import Link from 'next/link';
import { ComponentGlobalSectionHeading } from 'types/cms';
import Card from '../Cards';
import Carousel from '../Carousel';
import { cn } from '@/lib/utils';

const SectionWithCards: React.FC<ComponentGlobalSectionHeading> = ({
  highlightBackground,
  title,
  showFooter,
  footerLinkUrl,
  footerLinkText,
  useSlider,
  cardGroup,
}) => {
  const backgroundClass = highlightBackground ? 'bg-yellow-400' : 'bg-transparent';

  return (
    <div className={cn('w-full py-8 md:py-12', backgroundClass)}>
      <div className="container max-w-6xl">
        <h1 className="mb-4 text-center font-bold">{title}</h1>

        {useSlider ? (
          <Carousel data={cardGroup ?? []} view={<Card />} />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {cardGroup?.map((row) => (
              <Card key={row?.title} {...row} />
            ))}
          </div>
        )}

        {showFooter && (
          <div className="mt-6 text-center">
            <Link
              href={{ pathname: footerLinkUrl }}
              className="text-lg font-semibold text-gray-800 hover:underline"
            >
              {footerLinkText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionWithCards;
