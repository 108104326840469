import { formatToCurrency } from '@/utils/helpers';
import { Progress } from '@/components/ui/progress';

import React from 'react';

type ProgressBarProps = {
  targetAmount: number | undefined;
  amountRaised: number | undefined;
};

const ProgressBar = ({ targetAmount, amountRaised }: ProgressBarProps) => {
  const percentage =
    amountRaised && targetAmount && Math.round((amountRaised / targetAmount) * 100);
  return (
    <>
      <Progress value={percentage} />

      <div className="flex justify-between mb-5 mt-1">
        <span className="text-xs">{percentage}% complete</span>
        <span className="text-xs">of {formatToCurrency(targetAmount!)}</span>
      </div>
    </>
  );
};

export default ProgressBar;
