import { ComponentGlobalTitle } from 'types/cms';
import React from 'react';

const Title: React.FC<ComponentGlobalTitle> = ({ title, hideOnMobile }: ComponentGlobalTitle) => {
  return (
    <div className="container max-w-4xl my-4 md:my-6 xl:my-8 text-center">
      {title && <div dangerouslySetInnerHTML={{ __html: title }} className="cms-content" />}
    </div>
  );
};

export default Title;
