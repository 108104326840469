import React, { useState } from 'react';
import { ComponentGlobalNewsTickerList, NewsTickerEntity } from 'types/cms';
import { cn } from '@/lib/utils';

const NewsItems: React.FC<ComponentGlobalNewsTickerList> = ({
  news_ticker_items,
}: ComponentGlobalNewsTickerList) => {
  const [isPaused, setIsPaused] = useState(false);

  const newsItems = news_ticker_items?.data;
  if (!newsItems?.length) return null;

  const NewsItem = ({ item }: { item: NewsTickerEntity }) => (
    <div
      className="px-4 font-medium whitespace-nowrap flex-shrink-0"
      style={{ whiteSpace: 'nowrap' }}
    >
      <span className="font-bold text-yellow-400">{item.attributes?.prefix}</span>{' '}
      {item.attributes?.content}
    </div>
  );

  return (
    <div
      className="w-full mx-auto px-0 overflow-hidden"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
      role="region"
      aria-label="News updates"
    >
      <div className="bg-red-500 text-white p-2">
        <div className="w-full overflow-hidden container">
          <div
            className={cn(
              'animate-scroll whitespace-nowrap inline-flex',
              isPaused && 'animate-pause'
            )}
          >
            {newsItems?.map((item) => (
              <NewsItem key={`${item.id}`} item={item} />
            ))}
            {/* Duplicate items for seamless loop */}
            {newsItems?.map((item) => (
              <NewsItem key={`${item.id}-dup`} item={item} />
            ))}
          </div>
        </div>
      </div>

      <style jsx global>{`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-scroll {
          animation: scroll 23s linear infinite;
        }

        .animate-pause {
          animation-play-state: paused;
        }
      `}</style>
    </div>
  );
};

export default NewsItems;
