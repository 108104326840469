import React, { useEffect, useState } from 'react';
import {
  ComponentDonationQuickDonateOptions,
  Enum_Componentdonationquickdonateoptions_Frequency,
} from 'types/cms';
import {
  filterBySingle,
  filterByRegular,
  filterByFreqName,
  generateId,
  getRegularOptionList,
  getDisplayTextFreqList,
  displayNameForFrequency,
  filterByOtherAmount,
} from '../../utils/helpers';
import { useAppDispatch } from '../../redux/hooks';
import { addDonation, setIsSidebarOpen } from '../../redux/slices/donationSlice';
import { EnumIntention, StripeDonation } from '../../redux/slices/types';
import { useDisclosure } from '@/hooks/useDisclosure';
import CheckoutModal from '../CheckoutModal';
import { fbEvent } from '../Facebook/conversion-api';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '../ui/card';
import PaymentToggleButtonGroup from './PaymentToggleButtonGroup';
import FrequencySelector from './FrequencySelector';
import QuantityAndIntention from './QualityAndIntention';
import { UK_QURBANI_HOME_DELIVERY } from '@/consts/donations';
import UKQurbaniDetails from './UKQurbaniDetails';
import DonationOptions from './DonationOptions';

export type DonateBoxProps = {
  donateOptions: ComponentDonationQuickDonateOptions[];
  sfId?: string;
  zakat?: boolean;
  sadakah?: boolean;
  sadakahJariya?: boolean;
  pageInfo?: any;
  appealId?: string | undefined;
  donateDescription?: string;
  // sfUpsellProjectId?: string;
};
const DonateBox: React.FC<DonateBoxProps> = ({
  donateOptions,
  pageInfo,
  appealId,
  donateDescription,
}) => {
  const [maxQty, setMaxQty] = useState(10);
  const { sfId, sadakah, zakat, sadakahJariya } = pageInfo;
  const dispatch = useAppDispatch();
  const [donateOptionsView, setDonateOptionsView] = useState<ComponentDonationQuickDonateOptions[]>(
    donateOptions!
  );
  const [opened, { open, close }] = useDisclosure(false);
  const showIntentions = zakat || sadakah || sadakahJariya;
  const intentionList = [];
  sadakah && intentionList.push({ value: EnumIntention.Sadaqa, label: EnumIntention.Sadaqa });
  zakat && intentionList.push({ value: EnumIntention.Zakat, label: EnumIntention.Zakat });
  sadakahJariya &&
    intentionList.push({ value: EnumIntention.SadaqaJariya, label: EnumIntention.SadaqaJariya });

  const projectName = donateDescription || pageInfo.title;

  const singleDonateOptions = filterBySingle(donateOptions!);
  const regularDonateOptions = filterByRegular(donateOptions!);
  const regularFreqList = getRegularOptionList(regularDonateOptions);
  const isOnlySingle = singleDonateOptions.length >= 1 && regularDonateOptions.length === 0;
  const isOnlyRegular = singleDonateOptions.length === 0 && regularDonateOptions.length >= 1;
  const isMix = singleDonateOptions.length >= 1 && regularDonateOptions.length >= 1;
  const onlyOneRegularOptionAvailable = regularFreqList.length === 1;
  const regularFreqSelectList = getDisplayTextFreqList(regularFreqList);
  const firstValue = regularFreqSelectList[0]?.value;
  const [freq, setFreq] = useState<string>(firstValue);
  const [intention, setIntention] = useState<string | null>(intentionList[0]?.value || 'Sadaqa');

  const [isRegular, setIsRegular] = useState<Boolean>(false);

  const [appealForm, setAppealForm] = useState<StripeDonation>({
    appealId: appealId!,
    name: projectName,
    sku: sfId! || process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
    sfProjectId: sfId! || process.env.NEXT_PUBLIC_DEFAULT_SF_PROJECT_ID!,
    sfVariationId: '',
    amount: 0,
    interval: Enum_Componentdonationquickdonateoptions_Frequency.Single,
    id: generateId(),
    quantity: 1,
    intention,
    upsellId: pageInfo.sfUpsellProjectId,
    startDate: null,
    endDate: null,
    deliveryStreet: '',
    deliveryTown: '',
    deliveryPostCode: '',
    qurbaniFat: '',
    qurbaniSize: 'medium',
    qurbaniSpecialRequest: '',
  });

  const handlePaymentRecurring = (value: boolean) => {
    if (value) {
      setIsRegular((v) => value);
      setFreq(() => firstValue);
      setAppealForm((prevState) => {
        return {
          ...prevState,
          interval: firstValue,
        };
      });
      setDonateOptionsView(() => filterByFreqName(donateOptions!, firstValue));
      return;
    }
    setIsRegular((v) => value);
    setFreq(() => Enum_Componentdonationquickdonateoptions_Frequency.Single);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        interval: Enum_Componentdonationquickdonateoptions_Frequency.Single,
      };
    });
    setDonateOptionsView(() => singleDonateOptions);
  };

  const selectInitialOption = () => {
    if (isOnlyRegular) {
      handlePaymentRecurring(true);
      return;
    }
    if (isOnlySingle) {
      handlePaymentRecurring(false);
      return;
    }
    isRegular ? handlePaymentRecurring(true) : handlePaymentRecurring(false);
  };

  useEffect(() => {
    selectInitialOption();
    handlePriceChange(donateOptionsView[0].price!, donateOptionsView[0]);
  }, []);

  const handleFreqSelectChange = (item: Enum_Componentdonationquickdonateoptions_Frequency) => {
    setFreq(() => item);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        interval: item,
      };
    });
    setDonateOptionsView(filterByFreqName(donateOptions!, item));
  };

  const handleIntentionChange = (newVal: string) => {
    setIntention(() => newVal);
    setAppealForm((prevState) => {
      return {
        ...prevState,
        intention: newVal,
      };
    });
  };

  const handleSubmit = () => {
    fbEvent({
      enableStandardPixel: true,
      eventName: 'AddToCart',
      value: appealForm.amount,
      currency: 'GBP',
      products: [
        {
          sku: appealForm.sfProjectId,
          quantity: appealForm.quantity,
        },
      ],
    });
    setAppealForm({ ...appealForm, id: generateId() });
    dispatch(addDonation(appealForm));
    if (appealForm.startDate !== null && appealForm.endDate !== null) {
      open();
    } else {
      dispatch(setIsSidebarOpen(true));
    }
  };

  const handlePriceChange = (value: number, option: ComponentDonationQuickDonateOptions) => {
    const minimumAmount = value < 1 ? 1 : value;
    setAppealForm({
      ...appealForm,
      amount: minimumAmount,
      sfVariationId: option.sfId!,
      interval: option.frequency!,
      intention,
      startDate: option.startDate!,
      endDate: option.endDate!,
      quantity:
        appealForm.quantity! > (option.maxQuantity || 1)
          ? option.maxQuantity || 1
          : appealForm.quantity,
    });
    setMaxQty(option.maxQuantity!);
  };

  return (
    <Card>
      <CardContent className="p-3 space-y-3">
        {opened && <CheckoutModal opened={true} close={close} />}
        <PaymentToggleButtonGroup
          isMix={isMix}
          isOnlySingle={isOnlySingle}
          isOnlyRegular={isOnlyRegular}
          isRegular={!!isRegular}
          onlyOneRegularOptionAvailable={onlyOneRegularOptionAvailable}
          regularFreqList={regularFreqList}
          displayNameForFrequency={displayNameForFrequency}
          handlePaymentRecurring={handlePaymentRecurring}
        />

        <FrequencySelector
          isRegular={!!isRegular}
          onlyOneRegularOptionAvailable={onlyOneRegularOptionAvailable}
          freq={freq}
          regularFreqSelectList={regularFreqSelectList}
          onFreqChange={handleFreqSelectChange}
        />

        <DonationOptions
          donateOptionsView={donateOptionsView}
          appealForm={appealForm}
          handlePriceChange={handlePriceChange}
          filterByOtherAmount={filterByOtherAmount}
        />

        <QuantityAndIntention
          maxQty={maxQty}
          appealForm={appealForm}
          setAppealForm={setAppealForm}
          intention={intention}
          handleIntentionChange={handleIntentionChange}
          intentionList={intentionList}
          showIntentions={showIntentions}
        />

        {projectName === UK_QURBANI_HOME_DELIVERY && (
          <UKQurbaniDetails appealForm={appealForm} setAppealForm={setAppealForm} />
        )}

        <Button
          disabled={appealForm.amount === 0}
          fullWidth
          size="xl"
          variant="destructive"
          onClick={handleSubmit}
        >
          {projectName === UK_QURBANI_HOME_DELIVERY ? 'Order' : 'Donate'}{' '}
          {appealForm.amount ? `£${appealForm.amount}` : 0}
          {freq === 'day' ? ' daily' : ''}
          {freq === 'week' ? ' weekly' : ''}
          {freq === 'month' ? ' monthly' : ''}
          {freq === 'year' ? ' yearly' : ''}
          {/* {!isRegular && ' once'} */}
        </Button>
      </CardContent>
    </Card>
  );
};

export default DonateBox;
