import * as React from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

type CarouselProps<SlideData> = {
  data: SlideData[] | any;
  view: React.ReactElement<SlideData>;
};

const CarouselComponent = <SlideData extends Record<string, unknown>>({
  data,
  view,
}: CarouselProps<SlideData>) => {
  return (
    <Carousel className="w-full">
      <CarouselContent className="-ml-2">
        {data.map((slide: any, index: number) => (
          <CarouselItem key={index} className="px-2 basis-full sm:basis-1/2 lg:basis-1/3">
            <div>{React.cloneElement(view, { ...slide })}</div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <div className="hidden md:block">
        <CarouselPrevious />
        <CarouselNext />
      </div>

      <div className="block md:hidden">
        <CarouselDots />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
