import { ComponentGlobalRichText } from 'types/cms';
import React from 'react';

const RichText: React.FC<ComponentGlobalRichText> = ({
  content,
  anchorName,
}: ComponentGlobalRichText) => {
  return (
    <div className="container">
      <div
        id={anchorName!}
        className="cms-content"
        dangerouslySetInnerHTML={{ __html: content! }}
      />
    </div>
  );
};

export default RichText;
