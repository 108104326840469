import React from 'react';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';
import { StripeDonation } from '@/redux/slices/types';
import Required from '../ui/required';

export interface UKQurbaniDetailsProps {
  appealForm: StripeDonation;
  setAppealForm: React.Dispatch<React.SetStateAction<StripeDonation>>;
}

const UKQurbaniDetails: React.FC<UKQurbaniDetailsProps> = ({ appealForm, setAppealForm }) => {
  if (!appealForm || !setAppealForm) return null;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <div>
        <Label htmlFor="deliveryStreet">
          Door number & Street
          <Required />
        </Label>
        <Input
          id="deliveryStreet"
          value={appealForm.deliveryStreet || ''}
          onChange={(e) => setAppealForm({ ...appealForm, deliveryStreet: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="deliveryTown">
          Town/City <Required />
        </Label>
        <Input
          id="deliveryTown"
          value={appealForm.deliveryTown || ''}
          onChange={(e) => setAppealForm({ ...appealForm, deliveryTown: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="deliveryPostCode">
          Postcode <Required />
        </Label>
        <Input
          id="deliveryPostCode"
          value={appealForm.deliveryPostCode || ''}
          onChange={(e) => setAppealForm({ ...appealForm, deliveryPostCode: e.target.value })}
          required
        />
      </div>
      <div>
        <Label htmlFor="qurbaniFat">Keep Fat?</Label>
        <Select
          value={appealForm.qurbaniFat || undefined}
          onValueChange={(val) => setAppealForm({ ...appealForm, qurbaniFat: val })}
        >
          <SelectTrigger id="qurbaniFat">
            <SelectValue placeholder="Select an option" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="true">Fat on</SelectItem>
            <SelectItem value="false">Fat off</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="qurbaniSize">Cut Size</Label>
        <Select
          value={appealForm.qurbaniSize || 'medium'}
          onValueChange={(val) => setAppealForm({ ...appealForm, qurbaniSize: val })}
        >
          <SelectTrigger id="qurbaniSize">
            <SelectValue placeholder="Select a size" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="small">Small cut size</SelectItem>
            <SelectItem value="medium">Medium cut size</SelectItem>
            <SelectItem value="large">Large cut size</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="qurbaniSpecialRequest">Special Request</Label>
        <Input
          id="qurbaniSpecialRequest"
          value={appealForm.qurbaniSpecialRequest || ''}
          onChange={(e) => setAppealForm({ ...appealForm, qurbaniSpecialRequest: e.target.value })}
          placeholder="e.g., Keep legs whole, rest to be diced"
        />
      </div>
    </div>
  );
};

export default UKQurbaniDetails;
