import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { StripeDonation } from '@/redux/slices/types';

interface QuantityAndIntentionProps {
  maxQty: number;
  appealForm: StripeDonation;
  setAppealForm: React.Dispatch<React.SetStateAction<StripeDonation>>;
  intention: string | null;
  handleIntentionChange: (value: string) => void;
  intentionList: { value: string; label: string }[];
  showIntentions: boolean;
}

const QuantityAndIntention: React.FC<QuantityAndIntentionProps> = ({
  maxQty,
  appealForm,
  setAppealForm,
  intention,
  handleIntentionChange,
  intentionList,
  showIntentions,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {maxQty > 1 && (
        <div className="flex items-center justify-evenly space-x-2">
          <Button
            size="icon"
            onClick={() =>
              setAppealForm((prev) => ({
                ...prev,
                quantity: Math.max(1, prev.quantity - 1),
              }))
            }
            variant="secondary"
          >
            –
          </Button>
          <Input
            type="number"
            value={appealForm.quantity}
            onChange={(e) =>
              setAppealForm((prev) => ({
                ...prev,
                quantity: Math.min(maxQty, Math.max(1, Number(e.target.value))),
              }))
            }
            className="w-12 text-center hide-number-stepper"
            min={1}
            max={maxQty}
          />
          <Button
            size="icon"
            onClick={() =>
              setAppealForm((prev) => ({
                ...prev,
                quantity: Math.min(maxQty, prev.quantity + 1),
              }))
            }
            variant="secondary"
          >
            +
          </Button>
        </div>
      )}

      {showIntentions && (
        <Select value={intention || ''} onValueChange={handleIntentionChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Pick one" />
          </SelectTrigger>
          <SelectContent>
            {intentionList.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </div>
  );
};

export default QuantityAndIntention;
