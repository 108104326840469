import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { CheckoutModalType } from './CheckoutModalType';
import { UseFormReturnType } from '@mantine/form';
import { ErrorDisplay } from '../ui/error-display';

const CheckoutGuest = ({ form }: { form: UseFormReturnType<CheckoutModalType> }) => {
  return (
    <div className="space-y-4">
      <div>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          placeholder="you@gmail.com"
          required
          {...form.getInputProps('email')}
        />
        <ErrorDisplay error={form.errors.email} />
      </div>
    </div>
  );
};

export default CheckoutGuest;
