import React from 'react';
import {
  FundraisePageEntity,
  FundraiseTeamCampaign,
  FundraiseTeamCampaignEntity,
  Maybe,
} from 'types/cms';
import TeamAmountRaised from '@/components/AmountRaised/FundraiserTeam';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Badge } from '@/components/ui/badge';

interface LeaderboardGroupProps {
  sortedTeams: FundraiseTeamCampaignEntity[];
  sortedMemberPages: FundraisePageEntity[];
  calculateTeamTotalDonations: (team: Maybe<FundraiseTeamCampaignEntity>) => number;
  calculateMemberTotalDonations: (memberPage: FundraisePageEntity) => number;
}

const LeaderboardGroup: React.FC<LeaderboardGroupProps> = ({
  sortedTeams,
  sortedMemberPages,
  calculateTeamTotalDonations,
  calculateMemberTotalDonations,
}) => {
  return (
    <Tabs defaultValue="team" className="mt-8">
      <TabsList className="bg-primary">
        <TabsTrigger value="individual">Individual</TabsTrigger>
        <TabsTrigger value="team">Team</TabsTrigger>
      </TabsList>

      <TabsContent value="team">
        <Badge variant="destructive" inverse>
          Since start
        </Badge>
        {sortedTeams?.map((team, ind) => (
          <TeamAmountRaised
            key={team.id}
            slug={`/f/${team.attributes?.campaign_page?.data?.attributes?.slug}`}
            rank={ind + 1}
            targetAmount={team.attributes?.campaign_page?.data?.attributes?.goal || 0}
            amountRaised={calculateTeamTotalDonations(team)}
            teamName={team.attributes?.campaign_page?.data?.attributes?.name || ''}
            name={team.attributes?.campaign_page?.data?.attributes?.fullName || ''}
          />
        ))}
      </TabsContent>

      <TabsContent value="individual">
        <Badge variant="destructive" inverse>
          Since start
        </Badge>
        {sortedMemberPages.map((memberPage, ind) => (
          <TeamAmountRaised
            key={memberPage.id}
            slug={`/f/${memberPage?.attributes?.slug}`}
            rank={ind + 1}
            targetAmount={memberPage?.attributes?.goal || 0}
            amountRaised={calculateMemberTotalDonations(memberPage)}
            teamName={memberPage?.attributes?.name || ''}
            name={`${memberPage.attributes?.fullName} - ${(memberPage as any).teamName} `}
          />
        ))}
      </TabsContent>
    </Tabs>
  );
};

export default LeaderboardGroup;
