import React from 'react';

type ErrorDisplayProps = {
  error?: React.ReactNode;
};

export const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error }) => {
  if (!error) return null;
  return <p className="text-sm text-red-600 mt-1">{error}</p>;
};
