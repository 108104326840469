import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Enum_Componentdonationquickdonateoptions_Type } from 'types/cms';
import { cn } from '@/lib/utils';

interface DonationOptionsProps {
  donateOptionsView: any[];
  appealForm: any;
  handlePriceChange: (value: number, option: any) => void;
  filterByOtherAmount: (options: any[]) => any[];
}

const DonationOptions: React.FC<DonationOptionsProps> = ({
  donateOptionsView,
  appealForm,
  handlePriceChange,
  filterByOtherAmount,
}) => {
  const filteredOptions = donateOptionsView.filter(
    (el) => el.type !== Enum_Componentdonationquickdonateoptions_Type.Variable
  );

  const otherAmountOption = filterByOtherAmount(donateOptionsView);

  const gridColumns =
    filteredOptions.length >= 4
      ? 'grid-cols-4'
      : filteredOptions.length === 3
      ? 'grid-cols-3'
      : filteredOptions.length === 2
      ? 'grid-cols-2'
      : 'grid-cols-1';

  return (
    <div className="w-full">
      {/* Single option with input on the same row */}
      {filteredOptions.length === 1 ? (
        <div className="flex gap-3">
          <Button
            key={filteredOptions[0]?.id}
            variant={appealForm.amount === filteredOptions[0]?.price ? 'default' : 'secondary'}
            className="h-10 flex-1"
            onClick={() => handlePriceChange(filteredOptions[0]?.price || 0, filteredOptions[0])}
          >
            {filteredOptions[0]?.displayText}
          </Button>
          {otherAmountOption.length === 1 && (
            <Input
              placeholder="Other amount"
              type="number"
              min={1}
              step={0.01}
              onChange={(e) =>
                handlePriceChange(parseFloat(e.target.value) || 0, otherAmountOption[0])
              }
              className="flex-1 hide-number-stepper other-amount-input"
            />
          )}
        </div>
      ) : (
        <>
          {/* Grid for multiple donation options */}
          <div className={cn('grid gap-3', gridColumns)}>
            {filteredOptions.map((options) => (
              <Button
                key={options?.id}
                variant={appealForm.amount === options?.price ? 'default' : 'secondary'}
                className="h-10"
                onClick={() => handlePriceChange(options?.price || 0, options)}
              >
                {options?.displayText}
              </Button>
            ))}
          </div>

          {otherAmountOption.length === 1 && (
            <Input
              placeholder="Other amount"
              type="number"
              min={1}
              step={0.01}
              onChange={(e) =>
                handlePriceChange(parseFloat(e.target.value) || 0, otherAmountOption[0])
              }
              className={cn(
                'w-full hide-number-stepper other-amount-input',
                filteredOptions.length !== 0 ? 'mt-3' : null
              )}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DonationOptions;
