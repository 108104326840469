import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import DonateBox from '../DonateBox';

interface AppealsSectionProps {
  data: any;
  selectedAppeal: string;
  handleSelectChange: (value: string) => void;
  foundAppealsDataIndex: number;
}

const AppealsSection: React.FC<AppealsSectionProps> = ({
  data,
  selectedAppeal,
  handleSelectChange,
  foundAppealsDataIndex,
}) => (
  <>
    {data?.appeals?.data && data.appeals?.data?.length > 1 && (
      <div className="mb-2">
        <Select value={selectedAppeal} onValueChange={handleSelectChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select an appeal" />
          </SelectTrigger>
          <SelectContent>
            {data.appeals.data.map((appeal: { attributes: { title: string } }) => (
              <SelectItem key={appeal.attributes.title} value={appeal.attributes.title}>
                {appeal.attributes.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    )}
    {data?.appeals?.data && data?.appeals?.data?.length > 0 && (
      <DonateBox
        key={data.appeals.data[foundAppealsDataIndex].id}
        pageInfo={data.appeals.data[foundAppealsDataIndex].attributes}
        appealId={data.appeals.data[foundAppealsDataIndex].id!}
        // @ts-ignore
        donateOptions={data.appeals.data[foundAppealsDataIndex].attributes?.donateOptions}
      />
    )}
  </>
);

export default AppealsSection;
