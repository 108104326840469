import React, { useEffect, useState } from 'react';
import { Query, ComponentGlobalDonationQuery } from 'types/cms';
import fetch from 'isomorphic-unfetch';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import DonateBox from '../DonateBox';

const DonateQuery = ({ query, filters }: ComponentGlobalDonationQuery) => {
  const [data, setData] = useState<Query>();
  const [selectedAppeal, setSelectedAppeal] = useState<string>('');

  const foundAppealsDataIndex =
    data?.appeals?.data.findIndex((el) => el?.attributes?.title === selectedAppeal) || 0;

  useEffect(() => {
    const fetchData = () => {
      fetch('/api/run-query', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
          filters,
        }),
      })
        .then((resp) => resp.json())
        .then((info) => {
          setData(info);
          if (info?.appeals?.data?.length > 0) {
            setSelectedAppeal(info.appeals.data[0]?.attributes?.title || '');
          }
        });
    };
    fetchData();
  }, [filters, query]);

  const handleSelectChange = (value: string) => {
    if (value) {
      setSelectedAppeal(value);
    }
  };

  return (
    <div className="my-3 container max-w-2xl space-y-2">
      {data?.appeals?.data && data.appeals?.data?.length > 1 && (
        <Select value={selectedAppeal} onValueChange={handleSelectChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select an appeal" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Appeals</SelectLabel>
              {data.appeals.data.map((appeal) => (
                <SelectItem key={appeal.id} value={appeal.attributes?.title || ''}>
                  {appeal.attributes?.title}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
      {data?.appeals?.data && data?.appeals?.data?.length > 0 && (
        <DonateBox
          key={data?.appeals?.data[foundAppealsDataIndex].id}
          pageInfo={data?.appeals?.data[foundAppealsDataIndex].attributes}
          // @ts-ignore
          donateOptions={data?.appeals?.data[foundAppealsDataIndex].attributes?.donateOptions}
        />
      )}
    </div>
  );
};

export default DonateQuery;
