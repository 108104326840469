import React from 'react';
import Image from 'next/image';
import { ComponentGlobalImages } from 'types/cms';
import { getStrapiImage, ImageSizeNameEnum } from '@/utils/image/helpers';
import { cn } from '@/lib/utils';
import { convertColourToThemeColour, getTextColorForBg } from '@/utils/blockUtils';

const ImageList = ({ imageList, bg }: ComponentGlobalImages) => {
  return (
    <div className={cn('w-full', convertColourToThemeColour(bg), getTextColorForBg(bg))}>
      <div className="container">
        <div className="flex flex-wrap items-center justify-center md:justify-between gap-6 py-8">
          {imageList?.map((item) => {
            const imageUrl = getStrapiImage(
              item?.image?.data?.attributes!,
              ImageSizeNameEnum.large
            )?.url;
            const altText = item?.text || 'Image';

            return (
              <div key={item?.id} className="text-center">
                <div className="relative w-32 h-24 mx-auto">
                  {imageUrl && (
                    <Image
                      src={imageUrl}
                      alt={altText}
                      fill
                      sizes="(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 10vw"
                      style={{ objectFit: 'contain' }}
                    />
                  )}
                </div>
                <p className={(cn('text-xl font-medium mt-2'), getTextColorForBg(bg))}>
                  {item?.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ImageList;
