import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { NavigationItem } from 'types/cms';
import { useAppDispatch } from '@/redux/hooks';
import DesktopLogo from '@/public/logo.png';
import DrawerContent from './DrawerContent';
import NavigationLinks from './NavigationLinks';
import HeaderActions from './HeaderActions';

type ResponsiveHeaderProps = {
  nav: NavigationItem[];
};

const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = ({ nav }) => {
  const [opened, setOpened] = useState(false);
  const [showHeaderCTA, setShowHeaderCTA] = useState(false);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const { data } = useSession();

  useEffect(() => {
    const hideHeaderCtaCriteria =
      (router.pathname && router.pathname == '/f/[slug]') || router.pathname.includes('/fundraise');
    if (!hideHeaderCtaCriteria) {
      setShowHeaderCTA(true);
    }
    return () => {
      setShowHeaderCTA(false);
    };
  }, [router]);

  return (
    <header className="w-full relative z-30 h-[90px] bg-white">
      <div className="container flex h-full items-center justify-between px-4">
        <Link href="/">
          <Image src={DesktopLogo} alt="logo" width={100} />
        </Link>

        <NavigationLinks nav={nav} />

        <HeaderActions
          showHeaderCTA={showHeaderCTA}
          router={router}
          dispatch={dispatch}
          setOpened={setOpened}
        />
      </div>

      {opened && (
        <DrawerContent nav={nav} data={data} onClose={() => setOpened(false)} router={router} />
      )}
    </header>
  );
};

export default ResponsiveHeader;
