import React from 'react';
import Hero from '.';
import { Appeal, ComponentDonationQuickDonateOptions, ComponentHeroHero, Page } from 'types/cms';
import DonateBox from '../DonateBox';

type HeroQuickDonateProps = Partial<{
  hero: ComponentHeroHero;
  pageInfo: Page | Appeal;
  donateOptions: ComponentDonationQuickDonateOptions[];
}>;

// sfUpsellProjectId <-- to put this in state so we use at the end on thank you page
const HeroQuickDonate = ({ pageInfo, donateOptions, ...rest }: HeroQuickDonateProps) => {
  const { hero } = rest;

  return (
    <>
      <Hero {...hero}>
        <div className="max-w-sm">
          {donateOptions && (
            <DonateBox pageInfo={pageInfo} donateOptions={donateOptions} {...rest} />
          )}
        </div>
      </Hero>

      <div className="container mt-6">
        <div className="block sm:hidden mb-4">
          {donateOptions && (
            <DonateBox pageInfo={pageInfo} donateOptions={donateOptions} {...rest} />
          )}
        </div>
      </div>
    </>
  );
};

export default HeroQuickDonate;
