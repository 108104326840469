import React, { useRef } from 'react';
import Link from 'next/link';
import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';
import { NavigationItem } from 'types/cms';

type NavigationLinksProps = {
  nav: NavigationItem[];
};

const NavigationLinks: React.FC<NavigationLinksProps> = ({ nav }) => {
  return (
    <div className="hidden space-x-8 lg:flex">
      {nav.map((firstLevel) => (
        <HoverMenu key={firstLevel.id} title={firstLevel.title} items={firstLevel.items ?? []} />
      ))}
    </div>
  );
};

type HoverMenuProps = {
  title: string;
  items: NavigationItem['items'];
};

const HoverMenu: React.FC<HoverMenuProps> = ({ title, items = [] as NavigationItem['items'] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggleMenu);

  return (
    <div className="relative group">
      <div
        ref={ref}
        {...anchorProps}
        className="relative text-lg font-semibold text-gray-800 capitalize hover:text-gray-900 cursor-pointer"
      >
        {title}

        <span className="absolute left-0 -bottom-0 block h-[2px] w-0 bg-yellow-500 transition-all duration-300 group-hover:w-full"></span>
      </div>

      {(items ?? []).length > 0 && (
        <ControlledMenu
          {...hoverProps}
          {...menuState}
          anchorRef={ref}
          onClose={() => toggleMenu(false)}
          menuClassName="mt-2 bg-white shadow-md rounded-md"
        >
          {items?.map(
            (item) =>
              item && (
                <MenuItem key={item.id}>
                  <Link
                    href={item.path!}
                    className="block text-sm text-gray-700 hover:text-gray-600"
                  >
                    {item.title}
                  </Link>
                </MenuItem>
              )
          )}
        </ControlledMenu>
      )}
    </div>
  );
};

export default NavigationLinks;
