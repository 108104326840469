import React from 'react';
import { MdOutlineShoppingBasket, MdMenu } from 'react-icons/md';
import ProfileMenu from './ProfileMenu';
import { Button } from '@/components/ui/button';
import { event } from 'nextjs-google-analytics';
import { setIsSidebarOpen } from '@/redux/slices/donationSlice';

type HeaderActionsProps = {
  showHeaderCTA: boolean;
  router: any;
  dispatch: any;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderActions: React.FC<HeaderActionsProps> = ({
  showHeaderCTA,
  router,
  dispatch,
  setOpened,
}) => {
  return (
    <div className="flex items-center space-x-2 md:space-x-4">
      {showHeaderCTA && (
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
          <Button
            variant="default"
            onClick={() => {
              event('navDonateClick', { category: 'Nav clicks', label: 'DONATE' });
              router.push('/most-needy');
            }}
          >
            DONATE
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              event('navZakahClick', { category: 'Nav clicks', label: 'ZAKAH' });
              router.push('/appeals/zakah');
            }}
          >
            ZAKAH
          </Button>
        </div>
      )}

      <div className="flex items-center">
        <Button variant="ghost" size="icon" onClick={() => dispatch(setIsSidebarOpen(true))}>
          <MdOutlineShoppingBasket size={20} />
        </Button>

        <div className="hidden lg:block">
          <ProfileMenu />
        </div>

        <Button variant="ghost" size="icon" className="lg:hidden" onClick={() => setOpened(true)}>
          <MdMenu />
        </Button>
      </div>
    </div>
  );
};

export default HeaderActions;
